import { useFormContext } from 'react-hook-form'

import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface FormInputProps {
  title?: string
  description?: string | JSX.Element
  register: string
  placeholder: string
  disabled?: boolean
  requiredError?: string
  maxLength?: number
  className?: string
  tooltip?: string | JSX.Element
  titleClassName?: string
  descriptionClassName?: string
  type?: string
  isHalfOnLargeScreen?: boolean
  defaultValue?: string
  customErrorMsg?: string
  onBlur?: () => void
}

export default function FormInput({
  disabled,
  description,
  register,
  placeholder,
  title,
  requiredError,
  maxLength = 50,
  className,
  tooltip,
  titleClassName,
  descriptionClassName,
  type = 'text',
  isHalfOnLargeScreen,
  defaultValue,
  customErrorMsg,
  onBlur,
}: FormInputProps) {
  const formMethods = useFormContext()
  const registeredInput = formMethods.formState.errors[register]
  const errorMessage = customErrorMsg || formMethods.formState.errors[register]?.message

  return (
    <section className={className}>
      {title && (
        <h2 className={classNames('flex items-center text-sm font-semibold', titleClassName)}>
          {title}
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionMarkIcon className="ml-1 size-4" />
            </Tooltip>
          )}
        </h2>
      )}
      {description && <p className={classNames('text-sm text-gray-500', descriptionClassName)}>{description}</p>}
      <div className="mt-2">
        <input
          disabled={disabled}
          data-testid={`${register}-input`}
          className={classNames('block w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm', {
            'border-error focus:border-error focus:outline-none focus:ring-error': registeredInput,
            'focus:border-primary focus:outline-none focus:ring-primary': !registeredInput,
            'bg-gray-200': disabled,
            'w-full lg:w-1/2': isHalfOnLargeScreen,
          })}
          type={type}
          placeholder={placeholder}
          aria-invalid={!!registeredInput}
          defaultValue={defaultValue}
          {...formMethods.register(register, { maxLength })}
          onBlur={onBlur}
        />
        {requiredError && registeredInput && (
          <span className="mt-2 block text-sm text-error">{String(requiredError)}</span>
        )}

        {!requiredError && registeredInput && errorMessage && (
          <span className="mt-2 block text-sm text-error">{String(errorMessage)}</span>
        )}
      </div>
    </section>
  )
}
