import { RadioGroup } from '@headlessui/react'
import { Dispatch, SetStateAction } from 'react'

import { OrganisationInterface } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { CheckboxIcon } from '@/modules/shared/icons-special/CheckboxIcon'
import { generateOrgName } from '@/modules/shared/utils/generateOrgName'

interface SelectOrganisationAdminListProps {
  organisations: OrganisationInterface[]
  onSelectOrganisation: Dispatch<SetStateAction<number | null>>
}

export default function SelectOrganisationAdminList({
  organisations,
  onSelectOrganisation,
}: SelectOrganisationAdminListProps) {
  return (
    <RadioGroup data-testid="switch-organisation-options" className="divide-y rounded-md border border-gray-200">
      {organisations.map((organisation) => {
        return (
          <RadioGroup.Option
            value={organisation.id}
            key={organisation.id}
            onClick={() => onSelectOrganisation(Number(organisation.id))}
            className="w-full cursor-pointer bg-gray-100 p-3 first:rounded-t-md last:rounded-b-md"
            data-testid="switch-organisation-option"
          >
            {({ checked }) => (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-3">
                  <span className="shrink-0">
                    <CheckboxIcon isSelected={checked} />
                  </span>
                  <span>
                    {generateOrgName({
                      name: organisation.name,
                      parent: organisation?.parent,
                    })}
                  </span>
                </div>
                {organisation.id}
              </div>
            )}
          </RadioGroup.Option>
        )
      })}
    </RadioGroup>
  )
}
