import { Menu, Transition } from '@headlessui/react'
import { Dispatch, Fragment, PropsWithChildren, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import useSignOut from '@/modules/access/hooks/useSignout'
import UserMenu from '@/modules/requisitions/layouts/UserMenu'
import { Button } from '@/modules/shared/components/button/Button'
import { ArrowsRightLeftIcon } from '@/modules/shared/icons/ArrowsRightLeftIcon'
import { InfoIcon } from '@/modules/shared/icons/InfoIcon'
import { NewTabIcon } from '@/modules/shared/icons/NewTabIcon'
import { SignOutIcon } from '@/modules/shared/icons/SignOutIcon'
import SupportIcon from '@/modules/shared/icons/SupportIcon'
import { UserIcon } from '@/modules/shared/icons/UserIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface UserDetailsProps extends PropsWithChildren {
  customStyles?: string
  setOpenSwitchOrg: Dispatch<SetStateAction<boolean>>
  setShowAboutModal: Dispatch<SetStateAction<boolean>>
}

export default function UserDetails({ children, customStyles, setOpenSwitchOrg, setShowAboutModal }: UserDetailsProps) {
  const { t } = useTranslation()

  const [signOut, { loading: actionLoading }] = useSignOut()
  const [, setLiveChatEnabled] = useQueryParam('live-chat', StringParam)

  return (
    <Menu as="div" className={classNames('relative inline-block text-left', customStyles || '')}>
      <Menu.Button
        className="flex h-11 min-w-40 max-w-40 items-center rounded-md bg-gray-100 px-2 py-1 transition duration-200 hover:bg-gray-200 md:min-w-48 md:max-w-[25rem]"
        data-testid="user-details-btn"
      >
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 min-w-80 max-w-80 origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="user-details">
            <Menu.Item>
              <div>
                <UserMenu />
              </div>
            </Menu.Item>
          </div>
          <div className="py-1" data-testid="user-details-actions">
            <Menu.Item>
              {({ active }) => (
                <Link
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    { 'bg-gray-100 text-gray-900': active }
                  )}
                  to="/my-account/general"
                >
                  <UserIcon className="mr-2 size-5" />
                  {t('general.myAccount', 'My Account')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    { 'bg-gray-100 text-gray-900': active }
                  )}
                  onClick={() => setLiveChatEnabled('true')}
                >
                  <SupportIcon className="mr-2 size-5" />
                  {t('general.openLiveChat', 'Open Live Chat')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    { 'bg-gray-100 text-gray-900': active }
                  )}
                  onClick={() => setShowAboutModal(true)}
                >
                  <InfoIcon className="mr-2 size-5" />
                  {t('general.about', 'About')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    { 'bg-gray-100 text-gray-900': active }
                  )}
                  href={import.meta.env.VITE_APP_NINJA_API_HOST}
                  target="_blank"
                  rel="noreferrer"
                >
                  <NewTabIcon className="mr-2 size-5" />
                  {t('general.legacyPPlus', 'Go to Legacy P+')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  data-testid="user-switch-org"
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    { 'bg-gray-100 text-gray-900': active }
                  )}
                  onClick={() => setOpenSwitchOrg(true)}
                >
                  <ArrowsRightLeftIcon className="mr-2 size-5" />
                  {t('general.button.switchOrganisation', 'Switch Organisation')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  loading={actionLoading}
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    { 'bg-gray-100 text-gray-900': active }
                  )}
                  onClick={() => signOut()}
                >
                  <SignOutIcon className="mr-2 size-5" />
                  {t('general.signOut', 'Sign Out')}
                </Button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
