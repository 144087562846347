import { Trans, useTranslation } from 'react-i18next'

export default function GenericError() {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <h1 className="my-3 text-center text-xl font-bold">{t('general.somethingWentWrong', 'Something Went Wrong')}</h1>
      <p className="text-center text-sm text-gray-500">
        <Trans t={t} i18nKey="genericError.genericErrorMsg">
          We're having trouble loading this part of the page. You can try refreshing this page, or you can return{' '}
          <a data-testid="return-home-link" href="/" className="text-primary">
            home
          </a>
          .
        </Trans>
      </p>
    </div>
  )
}
