import { ColumnDefinition } from '@/modules/shared/components/table/types'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableNoSubLinesProps<T> {
  filteredColumns: ColumnDefinition<T>[]
}
export function TableNoSubLines<T>({ filteredColumns }: TableNoSubLinesProps<T>) {
  return (
    <>
      <tr>
        {filteredColumns.map((column, columnIdx) => {
          let nextIdx = columnIdx + 1
          let colSpan = 1

          // Check next columns for missing renderEmptySubLine
          while (nextIdx < filteredColumns.length && !filteredColumns[nextIdx].renderEmptySubLine) {
            colSpan++
            nextIdx++
          }

          return (
            column.renderEmptySubLine &&
            (!column?.hidden || column?.alwaysVisible) && (
              <td
                key={column?.key + 'subLine'}
                colSpan={colSpan}
                style={{ maxWidth: `${column?.maxWidth}rem`, minWidth: `${column?.minWidth}rem` }}
                className={classNames(column?.cellStyles, 'break-words border-b', {
                  'w-full': column?.grow,
                })}
              >
                {column.renderEmptySubLine()}
              </td>
            )
          )
        })}
      </tr>
    </>
  )
}
