import { Dispatch, SetStateAction, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Checkbox } from '@/modules/shared/components/checkbox/Checkbox'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import MeasureNameDropdown from '@/modules/shared/components/measure-name-dropdown/MeasureNameDropdown'
import NumberInput from '@/modules/shared/components/number-input/NumberInput'
import PackNameDropdown from '@/modules/shared/components/pack-name-dropdown/PackNameDropdown'
import { HELP_LINK_PRODUCT_CREATION } from '@/modules/shared/help'

interface CreateProductUnitSizeProps {
  defaultUnitSize: boolean
  setDefaultUnitSize: Dispatch<SetStateAction<boolean>>
  defaultItemSize?: number | null
  defaultMeasure?: string | null
  defaultItemPackName?: string | null
  disabled?: boolean
  description?: string
  hideCheckbox?: boolean
  hidePackName?: boolean
}

export default function CreateProductUnitSize({
  defaultUnitSize,
  setDefaultUnitSize,
  defaultItemSize,
  defaultMeasure,
  defaultItemPackName,
  disabled,
  description,
  hideCheckbox,
  hidePackName,
}: CreateProductUnitSizeProps) {
  const { t } = useTranslation()
  const formMethods = useFormContext<CreateProductFormInputs>()
  const [remount, setRemount] = useState(0)
  const [resetToDefault, setResetToDefault] = useState(false)

  const onUseDefaultUnitSize = () => {
    if (!defaultUnitSize) {
      setResetToDefault(true)
      setRemount(remount + 1)
      formMethods.setValue('itemMeasure', 'each')
      formMethods.setValue('itemSize', 1)
      !hidePackName && formMethods.setValue('itemPackName', 'null')
    }
    setDefaultUnitSize(!defaultUnitSize)
  }

  return (
    <div>
      <p className="font-bold">{t('general.unitSize', 'Unit Size')}</p>
      <div className="mb-4 text-gray-500">
        {description || (
          <Trans t={t} i18nKey="createProduct.unitSize.description">
            The size of 1 unit of this item e.g. a 375ml Can, or a 1kg Bag. If the product is a simple single item only
            with no applicable unit size, you can leave this section unchanged. Good product definitions are very
            important so please read our detailed{' '}
            <a
              data-testid="view-support-link"
              href={HELP_LINK_PRODUCT_CREATION}
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              product creation guide
            </a>{' '}
            to see examples of common product definitions.
          </Trans>
        )}
      </div>
      {!hideCheckbox && (
        <Checkbox
          onChange={onUseDefaultUnitSize}
          isSelected={defaultUnitSize}
          dataTestId="unit-size-checkbox"
          disabled={disabled}
        >
          <p>{t('createProduct.unitSizeCheckbox', 'Item is a single unit with no applicable Unit Size')}</p>
        </Checkbox>
      )}
      <div className="mt-4 flex flex-col gap-3 sm:flex-row" key={remount}>
        <div className="flex w-56 flex-col gap-y-1">
          <p className="font-bold">{t('general.unitSize', 'Unit Size')}</p>
          <Controller
            control={formMethods.control}
            name="itemSize"
            render={({ field }) => (
              <NumberInput
                className="h-[2.875rem] w-full rounded-md text-sm"
                defaultValue={(!resetToDefault && defaultItemSize) || 1}
                isDisabled={defaultUnitSize || disabled}
                onChange={(e) => field.onChange(e)}
                aria-label="item-size"
                data-testid="unit-size-input"
              />
            )}
          />
        </div>
        <div className="flex w-56 flex-col gap-y-1">
          <p className="font-bold">{t('general.unitOfMeasure', 'Unit of Measure')}</p>
          <MeasureNameDropdown
            disabled={defaultUnitSize || disabled}
            defaultValue={defaultMeasure}
            resetToDefault={resetToDefault}
          />
        </div>
        {!hidePackName && (
          <div className="flex w-56 flex-col gap-y-1">
            <p className="font-bold">{t('general.itemPackName', 'Item Pack Name')}</p>
            <PackNameDropdown
              disabled={defaultUnitSize || disabled}
              defaultValue={defaultItemPackName}
              resetToDefault={resetToDefault}
            />
          </div>
        )}
      </div>
    </div>
  )
}
