import { Transition } from '@headlessui/react'

import { Loading } from '@/modules/shared/icons-special/Loading'

interface TableOverlayLoadingProps {
  loading?: boolean
}

export default function TableOverlayLoading({ loading }: TableOverlayLoadingProps) {
  return (
    <Transition
      show={!!loading}
      enter="transition-opacity duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      data-testid="overlay-loading"
    >
      <div className="absolute z-10 flex size-full justify-center transition-all">
        <Loading className="mt-12 size-12 fill-gray-200 text-gray-300" />
      </div>
    </Transition>
  )
}
