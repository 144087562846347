import { useLazyQuery, useQuery } from '@apollo/client'
import { SetStateAction } from 'jotai'
import { Dispatch, useState } from 'react'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GetCategoriesDocument } from '@/graphql/purchasing/generated/getCategories.generated'
import { GetSubCategoriesDocument } from '@/graphql/purchasing/generated/getSubCategories.generated'
import { Category, RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button } from '@/modules/shared/components/button/Button'
import ComboboxServer from '@/modules/shared/components/combobox/server/ComboboxServer'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { TrashIcon } from '@/modules/shared/icons/TrashIcon'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

interface CatAndSubCatProps {
  defaultCategory?: Category
  setRemoveDefaultCategory?: Dispatch<SetStateAction<boolean>>
  removeDefaultCategory?: boolean
  title: string
  description: string
  register: string
  setCategoryObject?: Dispatch<SetStateAction<Category | null>>
  disabled?: boolean
}

export default function CatAndSubCat({
  defaultCategory,
  setRemoveDefaultCategory,
  removeDefaultCategory,
  title,
  description,
  register,
  setCategoryObject,
  disabled,
}: CatAndSubCatProps) {
  const { t } = useTranslation()
  const [categoryId, setCategoryId] = useState<number | null>(null)

  const { control, setValue } = useFormContext()

  const {
    data: categoriesData,
    networkStatus: categoriesNetworkStatus,
    refetch: refetchingCategories,
    fetchMore: fetchMoreCategories,
  } = useQuery(GetCategoriesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
      first: 10,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [
    fetchSubCategories,
    {
      data: subCategoriesData,
      networkStatus: subCategoriesNetworkStatus,
      refetch: refetchingSubCategories,
      fetchMore: fetchMoreSubCategories,
    },
  ] = useLazyQuery(GetSubCategoriesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
      first: 10,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const categories = extractEdges<Category>(categoriesData?.categories)
  const subCategories = extractEdges<Category>((subCategoriesData?.categories?.edges || [])[0]?.node?.categories)

  const hasMoreCategories = categoriesData?.categories?.pageInfo.hasNextPage
  const hasMoreSubCategories = (subCategoriesData?.categories?.edges || [])[0]?.node?.categories?.pageInfo.hasNextPage

  const onCategorySelect = (id: number) => {
    setCategoryId(id)
    fetchSubCategories({
      variables: {
        parentFilter: { q: [{ property: 'id_eq', value: id }] },
      },
    })
  }

  const onFetchMoreCategories = () => {
    fetchMoreCategories({ variables: { after: categoriesData?.categories?.pageInfo.endCursor } })
  }

  const onFetchMoreSubCategories = () => {
    fetchMoreSubCategories({
      variables: {
        after: (subCategoriesData?.categories?.edges || [])[0]?.node?.categories?.pageInfo.endCursor,
      },
    })
  }

  const onSelectSubCategory = (field: ControllerRenderProps, category: Category) => {
    field.onChange(category.id)
    setCategoryObject &&
      setCategoryObject({ ...category, parent: categories.find((category) => category.id === categoryId) as Category })
  }

  const onRemoveDefaultCategory = () => {
    setValue(register, null)
    setRemoveDefaultCategory && setRemoveDefaultCategory(true)
  }

  return (
    <section>
      <h2 className="font-semibold">{title}</h2>
      <p className="text-gray-500">{description}</p>
      <div className="mt-4 space-y-3">
        {removeDefaultCategory ||
          (defaultCategory && (
            <section
              className="flex w-full items-center justify-between rounded-md border border-gray-300 bg-gray-200 p-3"
              data-testid="default-category"
            >
              {(defaultCategory?.parent ? `${defaultCategory?.parent?.name} > ` : '') + defaultCategory?.name}
              <div className="flex items-center gap-x-1 text-gray-500">
                <span>{t('general.currentlySet', 'Currently Set')}</span>
                <Tooltip
                  content={t(
                    'mySuppliers.supplier.defaultCategoryCurrentlySetTooltip',
                    'The Category and Sub-Category currently set.'
                  )}
                >
                  <QuestionMarkIcon className="size-4" />
                </Tooltip>
                {!disabled && (
                  <Tooltip
                    content={t(
                      'mySuppliers.supplier.removeDefaultCategoryCurrentlySetTooltip',
                      'Remove the currently set Category and Sub-Category.'
                    )}
                  >
                    <Button
                      type="button"
                      data-testid="remove-default-category"
                      className="flex items-center justify-center"
                      onClick={() => !disabled && onRemoveDefaultCategory()}
                    >
                      <TrashIcon className="size-7" />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </section>
          ))}
        {(removeDefaultCategory || !defaultCategory) && (
          <>
            <ComboboxServer
              testId="categories"
              placeholder={t('general.selectACategory', 'Select a Category')}
              onDisplay={(e) => `${e.name}`}
              onInputChange={(e) => refetchingCategories({ filter: { q: [{ property: 'name_cont', value: e }] } })}
              networkStatus={categoriesNetworkStatus}
              keyExtractor={(e) => String(e.id)}
              items={categories}
              onSelected={(e) => onCategorySelect(e.id)}
              onFetchMore={onFetchMoreCategories}
              hasMore={hasMoreCategories}
              disabled={disabled}
            />
            <Controller
              control={control}
              name={register}
              render={({ field }) => (
                <ComboboxServer
                  testId="sub-categories"
                  placeholder={t('general.selectASubCategory', 'Select a Sub-Category')}
                  onDisplay={(e) => `${e.name}`}
                  onInputChange={(e) =>
                    categoryId && refetchingSubCategories({ filter: { q: [{ property: 'name_cont', value: e }] } })
                  }
                  networkStatus={subCategoriesNetworkStatus}
                  keyExtractor={(e) => String(e.id)}
                  items={subCategories}
                  loading={checkNetworkStatus(subCategoriesNetworkStatus).setVariablesLoading}
                  resetSelectItem={categoryId}
                  onSelected={(e) => onSelectSubCategory(field, e)}
                  onFetchMore={onFetchMoreSubCategories}
                  hasMore={hasMoreSubCategories}
                  disabled={!categoryId || disabled}
                />
              )}
            />
          </>
        )}
      </div>
    </section>
  )
}
