import { Dispatch, Fragment, SetStateAction } from 'react'

import { Checkbox } from '@/modules/shared/components/checkbox/Checkbox'
import { ColumnDefinition, LineProps, MultiRowSelection } from '@/modules/shared/components/table/types'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableRowProps<T> extends LineProps<T> {
  record: T
  index: number
  columns: ColumnDefinition<T>[]
  className?: string
  onRowClick?: (record: T, index: number) => void
  setSelectedRows: Dispatch<SetStateAction<T[]>>
  selectedRows: T[]
  multiRowSelection?: MultiRowSelection<T>
}

export default function TableRow<T>({
  record,
  index,
  columns,
  className,
  onRowClick,
  hasExtraLine,
  getSubLines,
  setSelectedRows,
  selectedRows = [],
  multiRowSelection,
}: TableRowProps<T>) {
  // Key to identify a row/record in the table
  const keyIdentifier = multiRowSelection?.key as keyof T

  // Check if the current row/record is selected
  const isRowSelected = Boolean(selectedRows.find((row) => row[keyIdentifier] === record[keyIdentifier]))

  const onRowSelect = () => {
    setSelectedRows((prevState) => {
      // `prevSelectedRows` and `selectedRows` are the same. But we should use `prevState` in the callback to get the latest updated state
      const prevSelectedRows = prevState
      if (!isRowSelected) {
        // If not selected, push the current row/record to the the list of selected rows
        return [...prevSelectedRows, record]
      } else {
        // If selected, remove the current row/record from the list of selected rows
        return prevSelectedRows.filter((row) => row[keyIdentifier] !== record[keyIdentifier])
      }
    })
  }

  return (
    <tr
      className={classNames(
        {
          'border-b': hasExtraLine ? !hasExtraLine(record) || (getSubLines && getSubLines(record).length) : true,
          'cursor-pointer hover:bg-gray-50': !!onRowClick,
          'bg-primary/10 hover:brightness-90': isRowSelected,
          'hover:bg-gray-50': !isRowSelected,
        },
        className
      )}
      onClick={() => onRowClick && onRowClick(record, index)}
    >
      {multiRowSelection && (
        <td className="px-3">
          <Checkbox
            square
            aria-label={`Select row ${index + 1}`}
            dataTestId={`select-row-${index + 1}`}
            onChange={() => onRowSelect()}
            isSelected={isRowSelected}
          />
        </td>
      )}
      {columns.map((column) => (
        <Fragment key={column?.key}>
          {(!column?.hidden || column?.alwaysVisible) && (
            <td
              // tailwind css does not support dynamic values (Ref: https://v2.tailwindcss.com/docs/just-in-time-mode#arbitrary-value-support)
              style={{ maxWidth: `${column?.maxWidth}rem`, minWidth: `${column?.minWidth}rem` }}
              className={classNames(column?.cellStyles, 'break-words', {
                'w-full': column?.grow,
              })}
            >
              {column?.render(record, index)}
            </td>
          )}
        </Fragment>
      ))}
    </tr>
  )
}
