import Bowser from 'bowser'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { isBrowserSupported } from '@/modules/shared/utils/isBrowserSupported'

export default function BrowserWarning() {
  const { t } = useTranslation()
  const browser = Bowser.getParser(window.navigator.userAgent)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const browserWaring = localStorage.getItem('browser-warning')
    if (!isBrowserSupported() && !browserWaring) {
      localStorage.setItem('browser-warning', 'true')
      setShowModal(true)
    }
  }, [])

  const onCloseModal = () => setShowModal(false)

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex h-fit w-full flex-col overflow-hidden rounded-md bg-white text-sm shadow-xl transition-all md:max-w-[35rem]">
        <Modal.Title
          title={t('general.yourBrowserIsNotSupported', 'Your Browser is Not Supported')}
          className="text-base"
          onCloseModal={onCloseModal}
        />
        <Modal.Body>
          <p className="text-sm text-gray-500">
            <Trans
              t={t}
              i18nKey="general.browserNotSupportedDescription"
              values={{ browser: browser.getBrowserName() }}
            >
              We have detected that the Browser you are using is out of date, and this may affect the functionality
              available to you when using PurchasePlus. PurchasePlus is not supported on your current Browser version.
              You may continue, but you may find some features do not work as expected. We recommend a recent version of{' '}
              {{ browser }} for the best experience.
            </Trans>
          </p>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            type="button"
            className="h-11 w-full whitespace-nowrap rounded-md bg-primary px-5 text-sm text-white md:w-fit"
            onClick={onCloseModal}
          >
            {t('general.okayGotIt', 'Okay, Got It')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
