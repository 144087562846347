import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { TileDetailsTableItem } from '@/modules/shared/components/tile/tile-items/TileDetailsTableItem'
import { AboutItem as AboutItemType } from '@/modules/shared/hooks/useAbout'

interface AboutItemProps {
  title: string
  items: AboutItemType[]
}

export default function AboutItem({ title, items }: AboutItemProps) {
  return (
    <Accordion title={title} buttonClassName="p-3 font-bold">
      <div className="mb-5 mt-3 border">
        {items.map((item, index) => (
          <TileDetailsTableItem
            key={index}
            title={item.title}
            value={item.value}
            tooltip={item.tooltip}
            className="sm:w-80 sm:min-w-80"
          />
        ))}
      </div>
    </Accordion>
  )
}
