import { MouseEventHandler } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import Button from '@/modules/shared/components/button/Button'
import { CloseIcon } from '@/modules/shared/icons/CloseIcon'

export const ToastContainerItem = () => {
  const renderCloseButton: React.FC<{
    closeToast: MouseEventHandler<HTMLButtonElement>
  }> = ({ closeToast }) => (
    <Button onClick={closeToast} className="mx-1">
      <CloseIcon className="size-6 text-gray-300 transition duration-200 hover:text-gray-400" />
    </Button>
  )
  return (
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      closeButton={renderCloseButton}
      transition={Slide}
    />
  )
}
