import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'

import LogoAStore from '@/assets/astore/logo.svg'
import LogoPPlus from '@/assets/pplus/logo.svg'
import { Button } from '@/modules/shared/components/button/Button'
import ScrollShadowWrapper from '@/modules/shared/components/scroll-shadow/ScrollShadowWrapper'
import CloseIconAlt from '@/modules/shared/icons/CloseIconAlt'
import { ElementSwitch } from '@/modules/shared/layouts/ElementSwitch'
import { SideBarContext } from '@/modules/shared/layouts/app-layout/SideBarContext'
import SideNavigation from '@/modules/shared/layouts/side-navigation/SideNavigation'

export default function Drawer() {
  const sideBar = useContext(SideBarContext)

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <Transition.Root show={sideBar?.drawerOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 xl:hidden" onClose={() => onCloseDrawer()}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/40" />
        </Transition.Child>
        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel
              className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-100"
              data-testid="drawer-navigation"
            >
              <div className="flex items-center justify-between border-b bg-white px-4 py-3">
                <Link to="/dashboard" onClick={() => onCloseDrawer()}>
                  <ElementSwitch
                    aStoreElement={<img src={LogoAStore} className="h-full" alt="astore" />}
                    defaultElement={<img src={LogoPPlus} className="h-full" alt="purchase-plus" />}
                  />
                </Link>
                <Button
                  data-testid="close-drawer-btn"
                  className="flex size-10 items-center justify-center rounded-full bg-gray-100"
                  onClick={() => onCloseDrawer()}
                >
                  <CloseIconAlt className="size-6" />
                </Button>
              </div>
              <ScrollShadowWrapper className="hide-scrollbar">
                <div className="overflow-y-auto px-3 pb-4 pt-5">
                  <SideNavigation />
                </div>
              </ScrollShadowWrapper>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
