import { useTranslation } from 'react-i18next'

import useGetProductSubstituteProducts from '@/modules/products/hooks/useGetProductSubstituteProducts'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import Table from '@/modules/shared/components/table/Table'
import { ProductImage } from '@/modules/shared/components/table/table-column-items/shared/ProductImage'
import { ProductItem } from '@/modules/shared/components/table/table-column-items/shared/ProductItem'

interface SubstituteProductsProps {
  productId: number
  isSearchProductUtility?: boolean
}

export default function SubstituteProducts({ productId, isSearchProductUtility }: SubstituteProductsProps) {
  const { t } = useTranslation()

  const { substituteProducts } = useGetProductSubstituteProducts({ productId: productId })

  return (
    <Accordion
      title={t('general.substituteProducts', 'Substitute Products')}
      buttonClassName="px-3 h-11 mt-2.5"
      titleClassName="font-bold"
      titleEndIcon={
        <div className="flex size-5 items-center justify-center rounded-md bg-gray-400 text-xs text-white">
          {substituteProducts.length}
        </div>
      }
    >
      <div className="mb-5">
        <p className="my-3 text-sm">
          {t(
            'productModal.substituteProductsDescription',
            'A Substitute Product is a product that your Organisation sees as similar to or exactly the same as the Product you are viewing. This can be important and useful for deciding which products on PurchasePlus are similar to others.'
          )}
        </p>
        <Table
          columns={[
            {
              title: t('myProducts.imageHeader', 'Image'),
              key: 'image',
              headerCellStyles: 'px-4 py-3 text-center',
              cellStyles: 'px-4 py-3 text-center',
              minWidth: 5.5,
              maxWidth: 5.5,
              header: {
                tooltip: t(
                  'myProducts.imageTooltip',
                  "The image belonging to this product in PurchasePlus, or the Supplier's image if an image has been added by the Supplier."
                ),
              },
              render: ({ substitute }) => <ProductImage image={String(substitute.image)} />,
            },
            {
              title: t('myProducts.productHeader', 'Product'),
              key: 'product',
              headerCellStyles: 'px-4 py-3',
              cellStyles: 'px-4 py-3',
              minWidth: 22,
              maxWidth: 22,
              alwaysVisible: true,
              grow: true,
              header: {
                tooltip: t(
                  'myProducts.productTooltip',
                  'The Brand, Item Name, Item Size and Sell Pack of the product.'
                ),
              },
              render: ({ substitute }) => <ProductItem product={substitute} isLink />,
            },
            {
              title: t('general.ownerID', 'Owner ID'),
              key: 'ownerID',
              headerCellStyles: 'py-3 text-center',
              cellStyles: 'py-3 text-center',
              minWidth: 6,
              maxWidth: 6,
              hidden: !isSearchProductUtility,
              header: {
                tooltip: t(
                  'myProducts.substitute.ownerIDTooltip',
                  'The ID of the Organisation that owns and manages this Substitute record.'
                ),
              },
              render: ({ substitute }) => substitute.owner?.id,
            },
            {
              title: t('myProducts.pPlusCodeHeader', 'P+ Code'),
              key: 'pPlusCode',
              headerCellStyles: 'px-1 py-3 text-center',
              cellStyles: 'px-1 py-3 text-center text-sm',
              minWidth: 7.5,
              maxWidth: 8.5,
              header: {
                tooltip: t(
                  'myProducts.pPlusCodeTooltip',
                  'The unique Code that is used to identify the product in the PurchasePlus system.'
                ),
              },
              render: ({ substitute }) => <Fallback condition={!!substitute?.id}>{substitute?.id}</Fallback>,
            },
          ]}
          dataSource={substituteProducts}
          keyExtractor={(record) => String(record.id)}
        />
        {substituteProducts.length === 0 && (
          <div className="border p-4 text-center text-gray-500">
            {t('product.substitutes.noSubstitutes', 'There are no Substitute Products to display.')}
          </div>
        )}
      </div>
    </Accordion>
  )
}
