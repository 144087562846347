import { ComponentProps, PropsWithChildren } from 'react'

import { classNames } from '@/modules/shared/utils/classNames'

interface ContainerProps extends PropsWithChildren<ComponentProps<'div'>> {
  layout?: 'xs' | 'sm' | 'md' | 'lg' | 'full'
}

export function Container({ layout = 'full', children, className, ...restProps }: ContainerProps) {
  return (
    <div
      className={classNames('mx-auto flex-grow space-y-5 px-4', className, {
        'max-w-[36rem]': layout === 'xs',
        'max-w-[50rem]': layout === 'sm',
        'max-w-[1580px]': layout === 'md',
        'max-w-[1920px]': layout === 'lg',
        'w-full': layout === 'full',
      })}
      {...restProps}
    >
      {children}
    </div>
  )
}
