import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Detects a change in the route and scrolls to the top of the page.
// This avoids new pages loading at the bottom of the page.
export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
