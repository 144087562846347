import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import SuspenseLoading from '@/modules/shared/components/suspense-loading/SuspenseLoading'

const ShopRoutes = lazy(() => import('@/routes/public-routes/ShopRoutes'))

// This PublicRoutes is for only not logged-in users.
export default function PublicRoutes() {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SuspenseLoading>
            <ShopRoutes />
          </SuspenseLoading>
        }
      />
    </Routes>
  )
}
