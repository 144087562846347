import { useMutation } from '@apollo/client'

import { CreateOrUpdatePartyProductDocument } from '@/graphql/purchase-plus/generated/createOrUpdatePartyProduct.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export function useCreateOrUpdatePartyProduct() {
  const mutation = useMutation(CreateOrUpdatePartyProductDocument, {
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
  })
  return mutation
}
