import { ComponentProps, FC, useRef } from 'react'
import { AriaTextFieldProps, useTextField } from 'react-aria'

import { classNames } from '@/modules/shared/utils/classNames'

interface InputProps extends AriaTextFieldProps {
  prefixIcon?: FC<ComponentProps<'svg'>>
  suffixIcon?: FC<ComponentProps<'svg'>>
  hasError?: boolean
  errorMessage?: string
  className?: string
  disabled?: boolean
}

export function Input(props: InputProps) {
  const ref = useRef<HTMLInputElement>(null)
  const { inputProps, errorMessageProps } = useTextField(props, ref)
  const SuffixIcon = props.suffixIcon
  const PrefixIcon = props.prefixIcon
  return (
    <div>
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          {PrefixIcon && <PrefixIcon className="size-5 text-gray-500" />}
        </div>
        <input
          {...inputProps}
          ref={ref}
          disabled={props.disabled || props.isDisabled}
          className={classNames(
            {
              'pl-9': Boolean(PrefixIcon),
              'pr-9': Boolean(SuffixIcon),
              'border-error focus:border-error focus:outline-none focus:ring-error': props.hasError,
              'focus:border-primary/30 focus:outline-none': !props.hasError,
              'cursor-not-allowed border-gray-300 bg-gray-200': props.isDisabled,
              'border-gray-200 bg-gray-100': props.disabled,
            },
            props.className
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {SuffixIcon && <SuffixIcon className="size-5 text-gray-500" />}
        </div>
      </div>
      {props.errorMessage && (
        <span {...errorMessageProps} className="mt-2 block text-sm text-error">
          {props.errorMessage}
        </span>
      )}
    </div>
  )
}
