import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useCreateContractCataloguedProduct } from '@/modules/buy-lists/pages/buy-list/hooks/useCreateContractCataloguedProduct'
import useCreatePricedCataloguedProduct from '@/modules/catalogs/hooks/useCreatePricedCatalogedProduct'
import { useGetPricedCatalog } from '@/modules/catalogs/hooks/useGetPricedCatalog'
import { useCreateOrUpdatePartyProduct } from '@/modules/products/hooks/useCreateOrUpdatePartyProduct'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import { UseCreateAndAddProductProps } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductRequisition'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { useCreateInformalProduct } from '@/modules/shared/hooks/useCreateInformalProduct'

export function useCreateAndAddProductCatalog({ quickAdd, refetch, onCloseModal, id }: UseCreateAndAddProductProps) {
  const { currentOrganisation } = useSessionUser()
  const { t } = useTranslation()
  const [createInformalProduct, { loading: createInformalProductLoading }] = useCreateInformalProduct()
  const { pricedCatalog } = useGetPricedCatalog({})
  const [createPricedCataloguedProduct, { loading: createPricedCatalogueProductLoading }] =
    useCreatePricedCataloguedProduct({
      catalogId: Number(id),
    })

  const [createContractPricedCataloguedProduct, { loading: createContractricedCatalogueProductLoading }] =
    useCreateContractCataloguedProduct({ catalogId: Number(id) })
  const navigate = useNavigate()

  const [createOrUpdatePartlyProduct] = useCreateOrUpdatePartyProduct()

  const onCreateAndAddProductCatalog = (input: CreateProductFormInputs) => {
    const {
      categoryId,
      brand,
      itemDescription,
      itemMeasure,
      itemSize,
      itemPackName,
      itemSellPackName,
      itemSellQuantity,
      unitPrice,
      taxPercentage,
      code,
    } = input
    createInformalProduct({
      variables: {
        input: {
          categoryId,
          brand,
          itemDescription,
          locale: 'en',
          itemMeasure,
          itemSize,
          itemPackName: itemPackName === 'null' ? null : itemPackName,
          itemSellPackName,
          itemSellQuantity,
        },
      },
      onCompleted: async (data) => {
        const createdProductId = Number(data.createInformalProduct.informalProduct?.id)
        if (code) {
          await createOrUpdatePartlyProduct({
            variables: {
              input: {
                productId: createdProductId,
                organisationId: Number(currentOrganisation?.id),
                code: code,
              },
            },
          })
        }
        const createCatalogProductPayload = {
          catalogueId: Number(id),
          productId: createdProductId,
          sellUnitPrice: unitPrice,
          sellUnitTaxPercentage: taxPercentage,
        }

        const onCompleted = () => {
          if (quickAdd) {
            onCloseModal && onCloseModal()
            displayToast({ title: t('general.productAdded', 'Product Added') })
            refetch && refetch()
          } else {
            navigate(generatePath('/catalogs/:catalogId/products', { catalogId: String(id) }))
          }
        }

        if (pricedCatalog?.contracted) {
          createContractPricedCataloguedProduct({
            variables: {
              input: createCatalogProductPayload,
            },
            onCompleted: () => {
              onCompleted()
            },
          })
        } else {
          createPricedCataloguedProduct({
            variables: {
              input: createCatalogProductPayload,
            },
            onCompleted: () => {
              onCompleted()
            },
          })
        }
      },
    })
  }

  return {
    onCreateAndAddProductCatalog,
    loading:
      createInformalProductLoading || createPricedCatalogueProductLoading || createContractricedCatalogueProductLoading,
  }
}
