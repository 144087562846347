import { useLazyQuery } from '@apollo/client'

import { GetOrganisationChildrenDocument } from '@/graphql/purchase-plus/generated/getOrganisationChildren.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

interface UseLazyGetOrganisationChildrenProps {
  organisationId: number
}

export default function useLazyGetOrganisationChildren({ organisationId }: UseLazyGetOrganisationChildrenProps) {
  const [fetchOrganisationChildren, { networkStatus, data, error, refetch }] = useLazyQuery(
    GetOrganisationChildrenDocument,
    {
      variables: {
        organisationId: Number(organisationId),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      context: {
        uri: PURCHASE_PLUS_GRAPHQL_API,
      },
    }
  )

  return {
    fetchOrganisationChildren,
    organisation: data?.organisation,
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    refetch,
  }
}
