export const measureNameValues = [
  { value: 'mg' },
  { value: 'g' },
  { value: 'kg' },
  { value: 'oz' },
  { value: 'a10' },
  { value: 'catty' },
  { value: 'lbs' },
  { value: 'pound' },
  { value: 'quart' },
  { value: 'tael' },
  { value: 'tonne' },
  { value: 'mm' },
  { value: 'cm' },
  { value: 'm' },
  { value: 'ft' },
  { value: 'inch' },
  { value: 'km' },
  { value: 'yard' },
  { value: 'cc' },
  { value: 'cl' },
  { value: 'gallon' },
  { value: 'ml' },
  { value: 'l' },
  { value: 'lt' },
  { value: 'ltr' },
  { value: 'liter' },
  { value: 'pint' },
  { value: 'fl oz' },
  { value: 'cup' },
  { value: 'teaspoon' },
  { value: 'tablespoon' },
  { value: 'dessertspoon' },
  { value: 'watt' },
  { value: 'Kva' },
  { value: 'Hz' },
  { value: 'MHz' },
  { value: 'v' },
  { value: 'volt' },
  { value: 'amp' },
  { value: 'ohm' },
  { value: 'KB' },
  { value: 'MB' },
  { value: 'GB' },
  { value: 'TB' },
  { value: 'Ns' },
  { value: 'ms' },
  { value: 'second' },
  { value: 'minute' },
  { value: 'hour' },
  { value: 'day' },
  { value: 'week' },
  { value: 'month' },
  { value: 'year' },
  { value: 'oct' },
  { value: 'octane' },
  { value: 'bag' },
  { value: 'basket' },
  { value: 'bottle' },
  { value: 'box' },
  { value: 'can' },
  { value: 'carton' },
  { value: 'case' },
  { value: 'drum' },
  { value: 'jar' },
  { value: 'packet' },
  { value: 'punnet' },
  { value: 'sachet' },
  { value: 'tin' },
  { value: 'tray' },
  { value: 'tub' },
  { value: 'tube' },
  { value: 'bale' },
  { value: 'bunch' },
  { value: 'bundle' },
  { value: 'ream' },
  { value: 'roll' },
  { value: 'set' },
  { value: 'bar' },
  { value: 'block' },
  { value: 'capsule' },
  { value: 'fillet' },
  { value: 'loaf' },
  { value: 'sheet' },
  { value: 'slab' },
  { value: 'slice' },
  { value: 'stem' },
  { value: 'stick' },
  { value: 'doz' },
  { value: 'dozen' },
  { value: 'each' },
  { value: 'pair' },
  { value: 'part' },
  { value: '%' },
  { value: 'piece' },
  { value: 'portion' },
  { value: 'ration' },
  { value: 'button' },
  { value: 'drawer' },
  { value: 'hole' },
  { value: 'leg' },
  { value: 'phase' },
  { value: 'pin' },
  { value: 'point' },
  { value: 'port' },
  { value: 'way' },
]
