import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '@/modules/shared/components/container/Container'
import { HELP_CENTER } from '@/modules/shared/help'
import { Header } from '@/modules/shared/layouts/header/Header'

function NotFound() {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('general.pplusPageNotFound', 'PurchasePlus | Page Not Found')}</title>
      </Helmet>
      <Header />
      <Container layout="xs" className="py-32">
        <div className="flex flex-col items-center">
          <h1 className="my-3 text-center text-xl font-bold">
            {t('notFound.title', "We couldn't find the page you're looking for.")}
          </h1>
          <p className="text-center text-sm text-gray-500">
            <Trans t={t} i18nKey="notFound.message">
              Hmmm, it seems that the page you're looking for doesn't exist. You can try going back the page you came
              from using the back button in your browser, or you can return to home using the button below. You can also
              visit our{' '}
              <a
                data-testid="view-support-link"
                href={HELP_CENTER}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                help center
              </a>{' '}
              if you require further assistance.
            </Trans>
          </p>
          <Link
            className="mt-8 w-full cursor-pointer rounded-md bg-gray-200 p-3 text-center text-sm transition hover:brightness-95"
            data-testid="view-home-link"
            to="/"
          >
            {t('general.returnToHome', 'Return to Home')}
          </Link>
        </div>
      </Container>
    </>
  )
}

export default NotFound
