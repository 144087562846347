import i18next from 'i18next'

import { RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { SortOption } from '@/modules/shared/components/table/types'

export const getCatalogProductSortOptions = (): SortOption[] => {
  return [
    {
      key: 'sellUnitPrice',
      isDefault: true,
      name: i18next.t('table.sortOptions.unitPriceLowToHigh', 'Unit Price - Lowest to Highest'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'sellUnitPrice',
      name: i18next.t('table.sortOptions.unitPriceHighToLow', 'Unit Price - Highest to Lowest'),
      direction: RansackDirection.Desc,
    },
  ]
}
