import { useRef } from 'react'
import { AriaCheckboxProps, useCheckbox } from 'react-aria'
import { useToggleState } from 'react-stately'

import { CheckboxIcon } from '@/modules/shared/icons-special/CheckboxIcon'
import { SquareCheckboxIcon } from '@/modules/shared/icons-special/CheckboxIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface CheckboxProps extends AriaCheckboxProps {
  square?: boolean
  dataTestId?: string
  disabled?: boolean
  reverse?: boolean
  classNames?: string
}

export function Checkbox(props: CheckboxProps) {
  const state = useToggleState(props)
  const ref = useRef(null)
  const { inputProps } = useCheckbox(props, state, ref)
  return (
    <label
      className={classNames('flex cursor-pointer items-center gap-2 text-sm', props.classNames)}
      onClick={(e) => props.disabled && e.preventDefault()}
    >
      {/* put the label in front of the checkbox if reversed */}
      {props.reverse && props.children}
      <input className="hidden" {...inputProps} ref={ref} data-testid={props.dataTestId} disabled={props.disabled} />
      {props.square ? (
        <SquareCheckboxIcon
          isSelected={state.isSelected}
          isIndeterminate={props.isIndeterminate}
          disabled={props.disabled}
        />
      ) : (
        <CheckboxIcon isSelected={state.isSelected} isIndeterminate={props.isIndeterminate} disabled={props.disabled} />
      )}
      {!props.reverse && props.children}
    </label>
  )
}
