import * as Sentry from '@sentry/react'
import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useSession } from '@/modules/access/hooks/useSession'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hsq: any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hbspt: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any
  }
}

type HubSpotWrapperProps = PropsWithChildren

export const HubSpotWrapper = ({ children }: HubSpotWrapperProps) => {
  const location = useLocation()

  const { currentUser } = useSession()

  // define user information to sentry and hubspot so it only runs once per login
  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        ...currentUser,
        email: currentUser.email || undefined,
        id: currentUser.id,
      })

      if (window._hsq) {
        const [firstName, lastName] = (currentUser.fullName || '').split(' ')
        window._hsq.push([
          'identify',
          {
            id: currentUser.id.toString(),
            email: currentUser.email,
            firstname: firstName,
            lastname: lastName,
            company: currentUser.defaultOrganisation?.name,
          },
        ])
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (window._hsq) {
      window._hsq.push(['setPath', location.pathname + location.search])
      window._hsq.push(['trackPageView'])
    }
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load() // This forces the widget to re-check the display rules.
      if (location.search.includes('live-chat=true')) {
        window.HubSpotConversations.widget.open() // Forces the chat open, if you have it default to closed for example.
      }
    }
  }, [location])

  return <>{children}</>
}
