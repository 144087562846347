import { useContext } from 'react'

import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { ShowMoreContext } from '@/modules/shared/contexts/ShowMoreContext'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { classNames } from '@/modules/shared/utils/classNames'

export interface InvoiceLineModalItemProps {
  title: string
  tooltip?: string | JSX.Element
  value?: string | number | null | JSX.Element
  showMore?: boolean // hide this item if showMoreDetails context is false
  className?: string
}

export const TileDetailsTableItem = ({ title, tooltip, value, showMore, className }: InvoiceLineModalItemProps) => {
  const { showMoreDetails } = useContext(ShowMoreContext) || {}
  const { sm } = useWindowSize()

  //   return null if this is a showMore item and showMoreDetails is false
  if (showMore && !showMoreDetails) return null

  return (
    <div className="flex border-b last:border-none" data-testid="item-wrapper">
      <div
        data-testid="item-title"
        className={classNames(
          'flex w-36 min-w-36 items-center border-r border-gray-300 bg-gray-100 p-3 px-2 text-xs sm:w-56 sm:min-w-56 sm:px-3 sm:text-sm',
          className
        )}
      >
        <span className="mr-1">{title}</span>
        {!!tooltip && sm && (
          <Tooltip content={tooltip}>
            <QuestionMarkIcon className="size-4" />
          </Tooltip>
        )}
      </div>
      <div className="flex items-center break-all px-2 py-3 text-xs sm:px-3 sm:text-sm">
        <Fallback condition={!!value || value === 0}>{value}</Fallback>
      </div>
    </div>
  )
}
