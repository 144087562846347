import { PropsWithChildren, useEffect, useState } from 'react'

import ScrollShadowWrapper from '@/modules/shared/components/scroll-shadow/ScrollShadowWrapper'
import TableControl from '@/modules/shared/components/table/TableControl'
import TableHeader from '@/modules/shared/components/table/TableHeader'
import TableMultiRowControl from '@/modules/shared/components/table/TableMultiRowControl'
import { TableNoSubLines } from '@/modules/shared/components/table/TableNoSubLines'
import TableOverlayLoading from '@/modules/shared/components/table/TableOverlayLoading'
import TableRow from '@/modules/shared/components/table/TableRow'
import { TableSubLine } from '@/modules/shared/components/table/TableSubLine'
import { PaginationDefinition } from '@/modules/shared/components/table/types'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableWrapperProps extends PropsWithChildren {
  dataTestId?: string
  loading?: boolean
  hasData?: boolean
  className?: string
  pagination?: PaginationDefinition
}

function TableComponent({ dataTestId, loading, hasData, children, className, pagination }: TableWrapperProps) {
  const [isScrollbarPresent, setIsScrollbarPresent] = useState(false)

  const onCheckScrollbarPresence = () => {
    const tableScrollbar = document.getElementById('table-scrollbar')
    if (tableScrollbar) {
      // this only check for horizontal scrollbar. Change to tableScrollbar.scrollHeight > tableScrollbar.clientHeight for vertical scrollbar.
      const isScrollbarPresent = tableScrollbar.scrollWidth > tableScrollbar.clientWidth
      setIsScrollbarPresent(isScrollbarPresent)
    }
  }
  useEffect(() => {
    onCheckScrollbarPresence()
    window.addEventListener('resize', onCheckScrollbarPresence)
    return () => window.removeEventListener('resize', onCheckScrollbarPresence)
  }, [])
  if (!hasData) return null

  return (
    <ScrollShadowWrapper
      direction="horizontal"
      className={classNames({ 'table-scrollbar border-b': isScrollbarPresent && pagination })}
      testId={dataTestId}
      id="table-scrollbar"
    >
      <TableOverlayLoading loading={loading} />
      <table
        className={classNames(
          'w-full bg-white text-left text-sm',
          {
            'opacity-50': loading,
          },
          className
        )}
      >
        {children}
      </table>
    </ScrollShadowWrapper>
  )
}

export const TableWrapper = Object.assign(TableComponent, {
  Header: TableHeader,
  Loading: TableOverlayLoading,
  Row: TableRow,
  SubLine: TableSubLine,
  NoSubLines: TableNoSubLines,
  Control: TableControl,
  MultiRowControl: TableMultiRowControl,
})
