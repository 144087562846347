export const dataPersistenceMapper = (data: string, typeNames?: string[]) => {
  const filteredData: Record<string, unknown> = {}
  const parsedData = JSON.parse(data)

  if (!typeNames) return data

  Object.keys(parsedData).forEach((key) => {
    const typeName = key.split(':')[0]

    if (typeNames.includes(typeName)) {
      filteredData[key] = parsedData[key]
    }
  })

  return JSON.stringify(filteredData)
}
