import { PropsWithChildren, Suspense } from 'react'

import { Spinner } from '@/modules/requisitions/components/spinner/Spinner'

// This component is used to show a loading spinner when a route/page is loading
export default function SuspenseLoading({ children }: PropsWithChildren) {
  return (
    <Suspense
      fallback={
        <div className="flex size-full h-screen justify-center pt-52">
          <Spinner className="h-28 md:h-32" />
        </div>
      }
    >
      {children}
    </Suspense>
  )
}
