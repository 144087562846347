import { Dispatch, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'

interface CreatePurchaseOrderModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function CreatePurchaseOrderModal({ showModal, setShowModal }: CreatePurchaseOrderModalProps) {
  const { t } = useTranslation()

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="create-purchase-order-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={t('createPurchaseOrder.title', 'Create a Purchase Order')} onCloseModal={onCloseModal} />
        <Modal.Body className="text-sm">
          <p className="text-gray-500">
            <Trans t={t} key="createPurchaseOrder.description">
              In PurchasePlus, a Purchase Order is generated automatically from a fully approved Purchase Requisition.
              You can't create a Purchase Order without first creating a Requisition. Use the Start Shopping button
              below to create a Requisition and begin ordering products from one or more of your Suppliers.
            </Trans>
          </p>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-5 py-3 text-sm md:w-auto"
            onClick={onCloseModal}
            data-testid="cancel-create-purchase-order-button"
          >
            {t('general.cancel', 'Cancel')}
          </Button>
          <Link
            className="flex w-full justify-center rounded-md bg-primary px-5 py-3 text-sm text-white md:w-auto"
            to="/requisitions/create"
            data-testid="create-requisition-button"
          >
            {t('general.startShopping', 'Start Shopping')}
          </Link>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
