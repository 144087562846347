import { useTranslation } from 'react-i18next'

import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { SupplierTagEnum } from '@/modules/shared/types/SupplierTagEnum'

interface SupplierTagProps {
  tag: SupplierTagEnum
}

export default function SupplierTag({ tag }: SupplierTagProps) {
  const { t } = useTranslation()

  const handleTooltip = () => {
    switch (tag) {
      case SupplierTagEnum.Preferred:
        return t(
          'shopPage.productList.productCard.preferredSupplierTooltip',
          'Your organisation has tagged this Supplier as a Preferred Supplier'
        )
      default:
        return ''
    }
  }

  return (
    <Tooltip content={handleTooltip()}>
      <div className="flex size-[1.1rem] items-center justify-center rounded-full bg-black text-xs font-bold text-white">
        {tag[0]}
      </div>
    </Tooltip>
  )
}
