import differenceWith from 'lodash/differenceWith'
import intersectionWith from 'lodash/intersectionWith'
import isEqual from 'lodash/isEqual'
import { Dispatch, SetStateAction } from 'react'

import { Checkbox } from '@/modules/shared/components/checkbox/Checkbox'
import TableHeaderCell from '@/modules/shared/components/table/TableHeaderCell'
import { ColumnDefinition, MultiRowSelection } from '@/modules/shared/components/table/types'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableHeaderProps<T> {
  updateColumnWidth: (column: ColumnDefinition<T>, width: number) => void
  resizeColumns?: boolean
  hidden?: boolean
  className?: string
  columns: ColumnDefinition<T>[]
  multiRowSelection?: MultiRowSelection<T>
  setSelectedRows: Dispatch<SetStateAction<T[]>>
  selectedRows: T[]
  recordsPerPage: T[]
}

export default function TableHeader<T>({
  updateColumnWidth,
  resizeColumns,
  hidden,
  className,
  columns,
  multiRowSelection,
  setSelectedRows,
  selectedRows = [],
  recordsPerPage,
}: TableHeaderProps<T>) {
  // Key to identify a row/record in the table
  const keyIdentifier = multiRowSelection?.key as keyof T

  // Return selected rows if there are selected rows in the current records
  const currentSelectedRows = intersectionWith(
    selectedRows,
    recordsPerPage,
    (a, b) => a[keyIdentifier] === b[keyIdentifier]
  )

  // Return unselected rows if there are unselected rows in the current records
  const currentUnSelectedRows = differenceWith(
    recordsPerPage,
    currentSelectedRows,
    (a, b) => a[keyIdentifier] === b[keyIdentifier]
  )

  const isAllSelect = currentUnSelectedRows.length === 0
  const isIndeterminate = currentSelectedRows.length > 0 && currentUnSelectedRows.length !== 0

  const onSelectAll = () => {
    setSelectedRows((prevState) => {
      // `prevSelectedRows` and `selectedRows` are the same. But we should use `prevState` in the callback to get the latest updated state
      const prevSelectedRows = prevState
      if (isAllSelect) {
        // If all rows are selected, remove them from the list of selected rows.
        return differenceWith(selectedRows, currentSelectedRows, isEqual)
      } else {
        // If there are unselected rows, add them to the list of selected rows.
        return [...prevSelectedRows, ...currentUnSelectedRows]
      }
    })
  }

  if (hidden) return null

  return (
    <thead className={classNames('border text-xs text-gray-500', className)}>
      <tr>
        {multiRowSelection && (
          <th className={classNames('px-3', resizeColumns && 'border-r')}>
            <Checkbox
              square
              aria-label="Select All"
              dataTestId="select-all-row-checkbox"
              onChange={() => onSelectAll()}
              isSelected={isAllSelect}
              isIndeterminate={isIndeterminate}
            ></Checkbox>
          </th>
        )}
        {columns.map((column, index) => (
          <TableHeaderCell<T>
            key={column.key}
            column={column}
            updateColumnWidth={updateColumnWidth}
            lastColumn={columns.length - 1 === index}
            resizeColumns={resizeColumns}
          />
        ))}
      </tr>
    </thead>
  )
}
