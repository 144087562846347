import { NormalizedCacheObject } from '@apollo/client'
import { CachePersistor } from 'apollo3-cache-persist'
import { PropsWithChildren, createContext } from 'react'

export interface ApolloPersistorCTXType {
  persistor: CachePersistor<NormalizedCacheObject>
}

interface ApolloPersistorProps extends PropsWithChildren {
  persistor: CachePersistor<NormalizedCacheObject>
}

const ApolloPersistorContext = createContext<ApolloPersistorCTXType | null>(null)

function ApolloPersistorProvider({ children, persistor }: ApolloPersistorProps) {
  return <ApolloPersistorContext.Provider value={{ persistor }}>{children}</ApolloPersistorContext.Provider>
}

export { ApolloPersistorProvider }

export default ApolloPersistorContext
