import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { ENTERPRISE_SIGN_IN_PAGE } from '@/modules/shared/constants'
import { useBackgroundReportPolling } from '@/modules/shared/hooks/useBackgroundReportPolling'
import isPublicRoutes from '@/modules/shared/utils/isPublicRoutes'
import PaperlessRoutes from '@/routes/all-routes/PaperlessRoutes'
import PublicRoutes from '@/routes/all-routes/PublicRoutes'
import PurchaserRoutes from '@/routes/all-routes/PurchaserRoutes'
import SupplierRoutes from '@/routes/all-routes/SupplierRoutes'

export default function AllRoutes() {
  const { loading, currentOrganisation } = useSessionUser()

  // Background tasks that need to run without unmounting
  useBackgroundReportPolling()

  if (loading) return null

  // user is signed in
  if (currentOrganisation) {
    const { isAPurchaser } = currentOrganisation

    if (!isAPurchaser) return <SupplierRoutes />
    if (isAPurchaser) {
      if (!currentOrganisation?.featureFlags?.paperlessInvoicingOnly) return <PurchaserRoutes />
      if (currentOrganisation?.featureFlags?.paperlessInvoicingOnly) return <PaperlessRoutes />
    }
  } else {
    if (isPublicRoutes()) {
      return <PublicRoutes />
    } else {
      window.location.href = ENTERPRISE_SIGN_IN_PAGE
    }
  }

  return null
}
