import { useMutation, useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import omit from 'lodash/omit'
import { useEffect, useMemo, useState } from 'react'

import { GetUserPreferenceDocument } from '@/graphql/access/generated/getUserPreference.generated'
import { UpdateUserPreferenceDocument } from '@/graphql/access/generated/updateUserPreference.generated'
import { useSession } from '@/modules/access/hooks/useSession'
import { ColumnDefinition, SortOption } from '@/modules/shared/components/table/types'

interface UseManageTablePreferencesProps<T> {
  tableName?: string
  columnsHidden?: string[]
  columns: ColumnDefinition<T>[]
  manageColumns?: boolean
  globalManageColumns?: boolean
  getSubLines?: boolean
  sortOptions?: SortOption[]
}

export interface ColumnFilterType {
  hidden: boolean | undefined
}

export interface ColumnPreferences {
  [key: string]:
    | {
        hidden?: boolean
        minWidth?: number
      }
    | undefined
}

// Manage table preferences(column visibility and sorting) based on user preferences
export default function useManageTablePreferences<T>({
  tableName,
  columnsHidden,
  columns,
  manageColumns,
  globalManageColumns,
  getSubLines,
  sortOptions,
}: UseManageTablePreferencesProps<T>) {
  const { currentUser } = useSession()
  const storageKey = `columns:${tableName}:${currentUser?.id}`
  // Temporary state to hold column preferences before debounced save
  const [tempColumnPreferences, setTempColumnPreferences] = useState<ColumnPreferences>({})

  const [updateUserPreference, { loading: updatingPreferences }] = useMutation(UpdateUserPreferenceDocument)
  const { data, loading: loadingPreferences } = useQuery(GetUserPreferenceDocument, {
    variables: { key: storageKey },
    // Only fetch the table preferences if manage table preferences is enabled
    skip: (!manageColumns && !globalManageColumns && !sortOptions?.length) || !tableName,
  })

  // Shared function to prepare preferences object
  const preparePreferencesObject = (
    updatedColumns: ColumnDefinition<T>[],
    defaultColumns: ColumnDefinition<T>[]
  ): ColumnPreferences => {
    return updatedColumns.reduce<ColumnPreferences>((acc, updatedColumn) => {
      const originalColumn = defaultColumns.find((defaultColumn) => defaultColumn.key === updatedColumn.key)
      acc[updatedColumn.key] = {
        hidden: updatedColumn.hidden !== originalColumn?.hidden ? updatedColumn.hidden : undefined,
        minWidth: updatedColumn.minWidth !== originalColumn?.minWidth ? updatedColumn.minWidth : undefined,
      }
      return acc
    }, {})
  }

  // Debounced function to update user preference
  const debouncedUpdatePreference = debounce((preferences: ColumnPreferences) => {
    updateUserPreference({
      variables: {
        input: {
          key: storageKey,
          value: JSON.stringify(preferences),
        },
      },
      onCompleted: () => {
        // Clear temporary preferences after update
        setTempColumnPreferences({})
      },
    })
  }, 500)

  // Function to handle immediate UI update and debounced backend update
  const updateColumnWidth = (column: ColumnDefinition<T>, minWidth: number) => {
    const columnKey = column.key // Define columnKey from the column parameter
    const tempExistingPreferences = getColumnPreferences()
    const originalColumn = columns.find((defaultColumn) => defaultColumn.key === columnKey)

    // Update the temp preferences with the updated column preference, ensuring minWidth is not less than col.minWidth
    const newTempPreferences = {
      ...tempColumnPreferences,
      [columnKey]: {
        ...tempExistingPreferences[columnKey],
        minWidth: minWidth <= (originalColumn?.minWidth || 0) ? originalColumn?.minWidth : minWidth,
      },
    }

    setTempColumnPreferences(newTempPreferences)

    // Merge column preferences with new temporary preferences for UI update
    const mergedPreferences = { ...getColumnPreferences(), ...newTempPreferences }
    // Convert merged preferences into ColumnDefinition<T>[] for preparePreferencesObject
    const updatedColumns = columns.map((col) => {
      const pref = mergedPreferences[col.key]
      return {
        ...col,
        minWidth: pref?.minWidth,
        hidden: pref?.hidden,
      }
    })

    const updatedPreferences = preparePreferencesObject(updatedColumns, columns)
    debouncedUpdatePreference(updatedPreferences)
  }

  const getColumnPreferences = (): ColumnPreferences => {
    const defaultHiddenPreference = columnsHidden
      ? columnsHidden.reduce<ColumnPreferences>((acc, key) => ({ ...acc, [key]: { hidden: true } }), {})
      : {}

    if ((!manageColumns && !globalManageColumns) || !tableName) return defaultHiddenPreference

    const savedPreferencesJSON = data?.preference?.value
    if (savedPreferencesJSON) {
      const savedPreferences = JSON.parse(savedPreferencesJSON) as ColumnPreferences
      const columnPreferencesWithWidth = columns.reduce<ColumnPreferences>(
        (acc, column) => {
          const columnPref = savedPreferences[column.key] || { hidden: column.hidden || false } // Ensure hidden is always defined
          acc[column.key] = { ...columnPref, minWidth: columnPref.minWidth || column.minWidth }
          return acc
        },
        { ...defaultHiddenPreference }
      )

      return { ...columnPreferencesWithWidth, ...tempColumnPreferences }
    }
    return defaultHiddenPreference
  }

  const getSortPreferences = () => {
    const defaultSortPreference = sortOptions?.find((sortOptions) => sortOptions.isDefault)
    const savedPreferencesJSON = data?.preference?.value

    if (savedPreferencesJSON) {
      // Pick only the `sorting` from preferences JSON
      const sorting = JSON.parse(savedPreferencesJSON).sorting as SortOption
      if (sorting && sorting.key) {
        // the current sort option based on sorting
        return sortOptions?.find(
          (sortOption) => sortOption.key === sorting.key && sortOption.direction === sorting.direction
        )
      } else {
        return defaultSortPreference
      }
    }
    return defaultSortPreference
  }

  const columnPreferences = useMemo(() => getColumnPreferences(), [data])
  const savedSortPreferences = useMemo(() => getSortPreferences(), [data])

  const filteredColumns = useMemo(() => {
    let columnsFiltered = !columnsHidden ? columns : columns.filter((column) => !columnsHidden.includes(column.key))
    // filter columns if sublines are enabled and hideOnSubLine is true for this column
    columnsFiltered = columnsFiltered.filter((column) => !(getSubLines && column.hideOnSubLine))
    return columnsFiltered.map((column) => {
      const tempWidth = tempColumnPreferences[column.key]?.minWidth
      const savedWidth = columnPreferences[column.key]?.minWidth
      const defaultWidth = column.minWidth
      // Use tempWidth if available, otherwise fallback to savedWidth, then defaultWidth
      const widthToUse = tempWidth !== undefined ? tempWidth : savedWidth !== undefined ? savedWidth : defaultWidth
      return {
        ...column,
        hidden: column.alwaysVisible
          ? false
          : columnPreferences[column.key]?.hidden !== undefined
            ? columnPreferences[column.key]?.hidden
            : column.hidden, // Make sure to use initial "hidden" value if not set in columnPreferences
        minWidth: widthToUse, // Use the temporary or saved width
      }
    })
  }, [columns, columnPreferences, tempColumnPreferences])

  // Handle side-effect for updating columns and refreshing the page
  const [rerenderState, setRerenderState] = useState(false)
  useEffect(() => {
    setRerenderState(!rerenderState)
  }, [columnPreferences, filteredColumns])

  // Function to handle updating filtered columns and saving changes to local storage
  const handleUpdateTablePreferences = (updatedColumns: ColumnDefinition<T>[], sortOption?: SortOption): void => {
    const updatedPreferences = preparePreferencesObject(updatedColumns, columns)

    // Prepare the object to be saved. If sortOption is provided, include it; otherwise, don't.
    const saveObject = sortOption ? { ...updatedPreferences, sorting: omit(sortOption, ['name']) } : updatedPreferences

    // Save updatedColumns and, optionally, updatedSortOption to the BE
    updateUserPreference({
      variables: {
        input: {
          key: storageKey,
          value: JSON.stringify(saveObject),
        },
      },
    })
  }

  return {
    filteredColumns,
    updateColumnWidth,
    handleUpdateTablePreferences,
    loadingPreferences,
    updatingPreferences,
    savedSortPreferences,
  }
}
