import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import { GetUserOrganisationsDocument } from '@/graphql/purchase-plus/generated/getUserOrganisations.generated'
import { OrganisationInterface } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

interface UseGetUserOrganisationsProps {
  userId: number
  lazyQuery?: boolean
}

export const useGetUserOrganisations = (args?: UseGetUserOrganisationsProps) => {
  const { userId, lazyQuery } = args || {}
  const paginationResultsPerPage = 20
  const [fetchUserOrganisations, { data, networkStatus, error, fetchMore, refetch }] = useLazyQuery(
    GetUserOrganisationsDocument,
    {
      variables: {
        userId: Number(userId),
      },
      context: { uri: PURCHASE_PLUS_GRAPHQL_API },
    }
  )

  const organisations = extractEdges<OrganisationInterface>(data?.user?.organisations)
  const pageInfo = data?.user?.organisations?.pageInfo

  useEffect(() => {
    if (!lazyQuery) fetchUserOrganisations()
  }, [])

  return {
    organisations,
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    pageInfo,
    onFetchMoreOrganisations: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    fetchUserOrganisations,
    refetch,
  }
}
