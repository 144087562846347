import { ComponentProps, forwardRef } from 'react'

import { classNames } from '@/modules/shared/utils/classNames'

interface ButtonProps extends ComponentProps<'button'> {
  loading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const { className, children, loading, disabled, ...restProps } = props
  return (
    <button
      ref={ref}
      {...restProps}
      disabled={disabled || loading}
      className={classNames(
        'transition duration-200',
        {
          'opacity-50': disabled || loading,
          'cursor-progress': loading,
          'cursor-not-allowed': disabled,
          'hover:brightness-95': loading ? false : disabled ? false : true,
        },
        className
      )}
    >
      {children}
    </button>
  )
})

Button.displayName = 'Button'

export default Button
