import { ComponentProps, PropsWithChildren } from 'react'

import { Themes } from '@/modules/shared/types/Themes'
import { classNames } from '@/modules/shared/utils/classNames'

interface ThemeWrapperProps extends PropsWithChildren<ComponentProps<'div'>> {
  theme: Themes
}

export function ThemeWrapper(props: ThemeWrapperProps) {
  const { children, className, theme, ...restProps } = props

  return (
    <div
      {...restProps}
      className={classNames(
        {
          'bg-primary/10 text-primary': theme === Themes.Primary,
          'bg-success/10 text-success': theme === Themes.Success,
          'bg-error/10 text-error': theme === Themes.Error,
          'bg-warning/10 text-warning': theme === Themes.Warning,
          'bg-purple/10 text-purple': theme === Themes.Purple,
        },
        className
      )}
    >
      {children}
    </div>
  )
}
