import { useAtom } from 'jotai'

import { GlobalManageColumns } from '@/modules/shared/states'

export default function useGlobalManageColumns() {
  const [isGlobalManageColumns, setIsGlobalManageColumn] = useAtom(GlobalManageColumns)

  return {
    openGlobalManageColumns: () => setIsGlobalManageColumn(true),
    closeGlobalManageColumns: () => setIsGlobalManageColumn(false),
    isGlobalManageColumns,
  }
}
