import { useMutation } from '@apollo/client'

import { CreateLegacyReceivingDocumentLineItemDocument } from '@/graphql/purchasing/generated/createLegacyReceivingDocumentLineItem.generated'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export function useCreateLegacyReceivingDocumentLineItem() {
  const mutation = useMutation(CreateLegacyReceivingDocumentLineItemDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return mutation
}
