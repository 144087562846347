import { useMutation } from '@apollo/client'

import { CreateRequisitionLineDocument } from '@/graphql/purchasing/generated/createRequisitionLine.generated'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export function useCreateRequisitionLine() {
  const mutation = useMutation(CreateRequisitionLineDocument, {
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  return mutation
}
