import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { BuyListIcon } from '@/modules/shared/icons/BuyListIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface BuyListSideBarItemProps extends SideBarItem {
  buyListRoutesMatch: boolean
}

export const BuyListSideBarItem = ({
  buyListRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: BuyListSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useSessionUser()
  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (userPermissions?.PURCHASER_CAN_MANAGE_BUY_LISTS) {
      sideNavItems.push({
        key: t('general.newBuyList', 'New Buy List'),
        target: '/buy-lists/create',
        icon: <PlusIcon className="size-6 text-gray-500" />,
      })
    }

    sideNavItems.push({
      key: t('general.viewAll', 'View All'),
      target: '/buy-lists',
    })

    if (userPermissions?.PURCHASER_CAN_ADD_ITEMS_TO_BUY_LISTS) {
      sideNavItems.push({ key: t('general.buyListBuilder', 'Buy List Builder'), target: '/buy-lists/builder' })
    }
    return sideNavItems
  }
  return (
    <Accordion
      titlePrefixIcon={<BuyListIcon className="size-7" />}
      title={t('general.buyLists', 'Buy Lists')}
      activeColor={buyListRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 3}
      handleTriggerClick={() => changeOpenAccordionKey(3)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="buyList-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
