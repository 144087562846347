import * as base64 from 'base-64'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/modules/shared/components/button/Button'
import { ConfirmDialog } from '@/modules/shared/components/confirm-dialog/ConfirmDialog'
import useGlobalManageColumns from '@/modules/shared/components/table/hooks/useGlobalManageColumns'
import ManageTablePreferencesModal from '@/modules/shared/components/table/manage-table-preferences/ManageTablePreferencesModal'
import { ColumnDefinition, PaginationDefinition, SortOption } from '@/modules/shared/components/table/types'
import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { ChevronLeftIcon } from '@/modules/shared/icons/ChevronLeftIcon'
import { ChevronRightIcon } from '@/modules/shared/icons/ChevronRightIcon'
import { SettingsIcon } from '@/modules/shared/icons/SettingsIcon'
import { Themes } from '@/modules/shared/types/Themes'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableControlProps<T> {
  /** pagination enabled with settings */
  pagination?: PaginationDefinition

  /** manage columns enabled */
  manageColumns?: boolean

  /** global manage columns enabled*/
  globalManageColumns?: boolean

  /** ensure that the column preference modal does not overlap.
   * Note: use this prop only if we want to manage columns of more than one table on the same page.
   * For example: view cart summary page has multiple identical tables and we want to manage it all at once.
   */
  columnPreferenceModalOverlapping?: boolean

  /** the column visibility settings */
  filteredColumns?: ColumnDefinition<T>[]

  /** set the column preferences */
  onPreferencesChange?: (filteredColumns: ColumnDefinition<T>[], sortOption: SortOption) => void

  /** Updating preferences */
  updatingPreferences?: boolean

  /** Default columns are used to restore default preferences. */
  defaultColumns?: ColumnDefinition<T>[]

  // Sort options
  sortOptions?: SortOption[]

  // sort option has been saved to the server
  savedSortPreferences?: SortOption

  /** indicates if it is the top control */
  top?: boolean

  /** Data that will be mapped in the table, used for page scrolling in control component */
  dataSource?: T[]
}

export default function TableControl<T>({
  pagination,
  manageColumns,
  filteredColumns,
  onPreferencesChange,
  globalManageColumns,
  columnPreferenceModalOverlapping,
  updatingPreferences,
  defaultColumns,
  sortOptions,
  savedSortPreferences,
  top,
  dataSource,
}: TableControlProps<T>) {
  const { t } = useTranslation()
  const [showComingSoon, setShowComingSoon] = useState(false)
  const [showManageColumns, setShowManageColumns] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const { isGlobalManageColumns, closeGlobalManageColumns } = useGlobalManageColumns()
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isGlobalManageColumns && globalManageColumns) setShowManageColumns(true)
  }, [isGlobalManageColumns])

  // Pagination settings
  const { rowsPerPage, hasNextPage, hasPreviousPage, onNextPage, onPreviousPage, loading, endCursor } = pagination || {}

  // Calculates the current page based on the endCursor and the rowsPerPage
  const calculateCurrentPage = (endCursor: string | null | undefined, itemsPerPage: number): number => {
    // if no endCursor then we are on the first page
    if (!endCursor) return 1
    const endCursorDecoded = base64.decode(endCursor) // converts the endCursor to a number string
    const endCursorInt = parseInt(endCursorDecoded, 10) // converts the endCursor to an integer
    const currentPage = Math.floor(endCursorInt / itemsPerPage) // calculates the current page from the rows

    // if the remainder of endCursorInt / itemsPerPage is greater than 0 then currenPage +1 otherwise we are on the last pages
    return endCursorInt % itemsPerPage > 0 ? currentPage + 1 : currentPage
  }

  const currentPage = calculateCurrentPage(endCursor, rowsPerPage || 20)

  useEffect(() => {
    if (top) {
      if (initialLoad) {
        setInitialLoad(false)
      } else if (!hasNextPage && dataSource && dataSource?.length > 0) {
        // Only scroll up if there are results and on the last page, stops scrolling when there are no more results
        scrollRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  }, [hasNextPage])

  return (
    <>
      {(pagination || manageColumns) && (
        <div className="mb-5 flex items-center justify-between" ref={scrollRef}>
          {!!pagination && (
            <div className="flex items-center justify-between">
              <Button
                data-testid="rows-per-page-btn"
                className="hidden items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 hover:brightness-95 lg:flex"
                onClick={() => setShowComingSoon(true)}
              >
                <span className="text-sm">
                  {t('requisition.details.rowsPerPage.information', {
                    defaultValue_one: '{{ count }} Row per Page',
                    defaultValue_many: '{{ count }} Rows per Page',
                    defaultValue_other: '{{ count }} Rows per Page',
                    count: rowsPerPage || 20,
                  })}
                </span>
                <ChevronDownIcon className="size-6" />
              </Button>
              <div className="ml-3 text-sm">
                {hasNextPage || loading
                  ? t('requisition.details.pageNumberOnly', 'Page {{ pageNumber }}', {
                      pageNumber: currentPage,
                    })
                  : t('requisition.details.pageNumberLast', 'Page {{ pageNumber }} of {{ pageNumber }}', {
                      pageNumber: currentPage,
                    })}
              </div>
            </div>
          )}
          <div
            className={classNames('flex items-center gap-x-2', {
              'w-full justify-end': !pagination && manageColumns,
            })}
          >
            {manageColumns && (
              <Button
                data-testid="manage-cols-btn"
                className="flex items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 hover:brightness-95"
                onClick={() => setShowManageColumns(true)}
              >
                <span className="text-sm">{t('general.manageColumns', 'Manage Columns')}</span>
                <SettingsIcon className="size-6" />
              </Button>
            )}
            {!!pagination && (
              <>
                <Button
                  data-testid="previous-page-btn"
                  disabled={!hasPreviousPage}
                  loading={loading}
                  className="rounded-md bg-gray-200 px-3 py-2"
                  onClick={onPreviousPage}
                >
                  <ChevronLeftIcon className="size-6" />
                </Button>
                <Button
                  data-testid="next-page-btn"
                  disabled={!hasNextPage}
                  loading={loading}
                  className="rounded-md bg-gray-200 px-3 py-2"
                  onClick={onNextPage}
                >
                  <ChevronRightIcon className="size-6" />
                </Button>
              </>
            )}
          </div>
        </div>
      )}

      {!!pagination && (
        <ConfirmDialog
          theme={Themes.Primary}
          isOpen={showComingSoon}
          setIsOpen={setShowComingSoon}
          title={t('general.comingSoon', 'Coming Soon!')}
          description={t(
            'requisition.details.rowsPerPage.comingSoonContent',
            "Soon you will be able to change the Rows per Page here, but we're still working on it."
          )}
          primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
          onPrimaryBtnClick={() => setShowComingSoon(false)}
        />
      )}
      {(manageColumns || globalManageColumns) && filteredColumns && onPreferencesChange && (
        <ManageTablePreferencesModal
          defaultColumns={defaultColumns}
          showModal={showManageColumns && !!columnPreferenceModalOverlapping}
          setShowModal={() => {
            setShowManageColumns(false)
            closeGlobalManageColumns()
          }}
          filteredColumns={filteredColumns}
          onPreferencesChange={onPreferencesChange}
          updatingPreferences={!!updatingPreferences}
          sortOptions={sortOptions}
          savedSortPreferences={savedSortPreferences as SortOption}
        />
      )}
    </>
  )
}
