import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import BrowserWarning from '@/modules/shared/components/browser-warning/BrowserWarning'
import ScrollShadowWrapper from '@/modules/shared/components/scroll-shadow/ScrollShadowWrapper'
import SuspenseLoading from '@/modules/shared/components/suspense-loading/SuspenseLoading'
import useShouldHideLayout from '@/modules/shared/hooks/useShouldHideLayout'
import Drawer from '@/modules/shared/layouts/app-layout/Drawer'
import { SideBarContext } from '@/modules/shared/layouts/app-layout/SideBarContext'
import { Header } from '@/modules/shared/layouts/header/Header'
import SideNavigation from '@/modules/shared/layouts/side-navigation/SideNavigation'
import { classNames } from '@/modules/shared/utils/classNames'

export default function AppLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(true)
  const { shouldHideLayout } = useShouldHideLayout()

  if (shouldHideLayout) {
    return (
      <SuspenseLoading>
        <Outlet />
      </SuspenseLoading>
    )
  }

  return (
    <div>
      <BrowserWarning />
      <Header
        setDrawerOpen={setDrawerOpen}
        setToggleSidebar={setToggleSidebar}
        toggleSidebar={toggleSidebar}
        showSidebarButtons
      />
      <SideBarContext.Provider value={{ setDrawerOpen, drawerOpen }}>
        <Drawer />
        <div
          className={classNames(
            'hidden h-full transform transition-all duration-200 ease-linear xl:fixed xl:flex xl:w-64 xl:flex-col',
            {
              '-translate-x-full': !toggleSidebar,
              'translate-x-0': toggleSidebar,
            }
          )}
          data-testid="static-side-navigation"
        >
          <div className="flex grow flex-col overflow-y-auto pl-4 pt-[4.3125rem] hide-scrollbar">
            <ScrollShadowWrapper className="hide-scrollbar">
              <div className="mt-5 flex flex-1 flex-col">
                <SideNavigation />
              </div>
            </ScrollShadowWrapper>
          </div>
        </div>
      </SideBarContext.Provider>
      <div
        className={classNames('mb-12 flex flex-1 flex-col py-[4.3125rem] transition-all duration-200 ease-linear', {
          'xl:pl-64': toggleSidebar,
        })}
      >
        <div className="mt-5 overflow-hidden">
          <SuspenseLoading>
            <Outlet />
          </SuspenseLoading>
          <div className="h-96" />
        </div>
      </div>
    </div>
  )
}
