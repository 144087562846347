import { WatchQueryFetchPolicy, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetInvoiceDocument } from '@/graphql/purchasing/generated/GetInvoice.generated'
import { Invoice, RansackDirection, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

interface UseGetInvoiceProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export function useGetInvoice(args?: UseGetInvoiceProps) {
  const { fetchPolicy } = args || {}
  const { invoiceId } = useParams<{ invoiceId: string }>()

  const { networkStatus, error, data, refetch } = useQuery(GetInvoiceDocument, {
    variables: {
      invoiceId: Number(invoiceId),

      sortComments: [{ property: 'created_at', direction: RansackDirection.Asc }],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: fetchPolicy || 'cache-first',
    skip: !invoiceId,
  })

  const invoice = data?.currentPurchaser?.invoice

  const {
    invoiceNumber,
    reference,
    supplier,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExTax,
    totalValue,
    totalTaxValue,
    status,
    purchaseOrderId,
    commentsCount,
    inception,
    purchaseOrder,
    department,
    account,
    createdAt,
    invoiceDate,
    lineTotal,
    lineTaxTotal,
    adjustmentExTax,
    adjustmentTax,
    flaggingMessages,
    receivingDocuments,
    filesCount,
  } = invoice || {}

  const getDefaultValues = (invoice: Invoice) => {
    return {
      accountId: invoice?.account?.id || null,
      departmentId: Number(invoice?.department?.id),
      reference: String(invoice?.reference),
      invoiceNumber: String(invoice?.invoiceNumber),
      invoiceDate: String(invoice?.invoiceDate),
      currency: String(invoice?.currency),
      taxCodeId: invoice?.taxCodeId,
    }
  }

  return {
    invoice,
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    invoiceNumber,
    reference,
    supplier: supplier as Supplier,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExTax,
    totalValue,
    totalTaxValue,
    status,
    purchaseOrderId,
    invoiceId,
    commentsCount,
    inception,
    purchaseOrder,
    department,
    account,
    createdAt,
    invoiceDate,
    lineTotal,
    lineTaxTotal,
    adjustmentExTax,
    adjustmentTax,
    receivingDocuments,
    filesCount,
    getDefaultValues,
    flaggingMessages,
    refetch,
  }
}
