import { useTranslation } from 'react-i18next'

export interface BooleanItemProps {
  value: boolean
}

export function BooleanItem({ value }: BooleanItemProps) {
  const { t } = useTranslation()
  return <>{value ? t('general.yes', 'Yes') : t('general.no', 'No')}</>
}
