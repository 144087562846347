import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import SupplierTag from '@/modules/shared/components/supplier-tags/SupplierTag'
import { SupplierTagEnum } from '@/modules/shared/types/SupplierTagEnum'

interface SupplierTagsProps {
  preferredSupplier: boolean
}

export default function SupplierTags({ preferredSupplier }: SupplierTagsProps) {
  const hasTags = preferredSupplier

  return <Fallback condition={hasTags}>{preferredSupplier && <SupplierTag tag={SupplierTagEnum.Preferred} />}</Fallback>
}
