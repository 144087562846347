import { useState } from 'react'
import { Link, generatePath } from 'react-router-dom'

import { Product } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import {
  CreditNoteLine,
  InvoiceLineItem,
  Product as LegacyProduct,
  PurchaseOrderLineItem,
  RequisitionLine,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils/formatProductItem'
import { Input as FormatProductInput } from '@/modules/requisitions/utils/formatProductItem'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { Supplier } from '@/modules/shared/components/product-modal/types/Supplier'
import { classNames } from '@/modules/shared/utils/classNames'

export interface ProductItemProps {
  product: LegacyProduct | Product
  clickToOpenModal?: boolean
  supplier?: Supplier
  line?: RequisitionLine | PurchaseOrderLineItem | InvoiceLineItem | CreditNoteLine
  hideName?: boolean
  isLink?: boolean
  customProductLink?: string
  formatProduct?: (input: FormatProductInput) => string
}

export const ProductItem = ({
  product,
  clickToOpenModal,
  supplier,
  line,
  hideName,
  isLink = false,
  customProductLink,
  formatProduct,
}: ProductItemProps) => {
  const { itemDescription, brand, itemSize, itemMeasure, itemPackName, concatenatedSellUnit } = product || {}
  const [showProductDetailsModal, setShowProductDetailsModal] = useState<boolean>(false)

  return (
    <div className="flex flex-col">
      {!hideName && !isLink && (
        <p
          className={classNames('w-fit break-words leading-5  text-primary', { 'cursor-pointer': clickToOpenModal })}
          data-testid="product-details-button"
          onClick={() => clickToOpenModal && setShowProductDetailsModal(true)}
        >
          {brand} {itemDescription}
        </p>
      )}
      {!hideName && isLink && (
        <Link
          to={customProductLink || generatePath('/products/:productId/details', { productId: String(product.id) })}
          className="w-fit break-words leading-5 text-primary"
        >
          {brand} {itemDescription}
        </Link>
      )}
      <span className="text-xs text-gray-500">
        {formatProduct
          ? formatProduct({
              itemSize: itemSize,
              itemMeasure: itemMeasure,
              itemPackName: itemPackName,
              itemSellUnit: concatenatedSellUnit,
            })
          : formatProductItem({
              itemSize: itemSize,
              itemMeasure: itemMeasure,
              itemPackName: itemPackName,
              itemSellUnit: concatenatedSellUnit,
            })}
      </span>
      {(line || product) && (
        <ProductModal
          showModal={showProductDetailsModal}
          setShowModal={setShowProductDetailsModal}
          line={line}
          supplier={supplier}
          product={product}
          formatProduct={formatProduct}
        />
      )}
    </div>
  )
}
