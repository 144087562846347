import { Dispatch, SetStateAction, useState } from 'react'

import { OrganisationInterface } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useLazyGetOrganisationChildren from '@/modules/admin-center/hooks/useLazyGetOrganisationChildren'
import { Checkbox } from '@/modules/shared/components/checkbox/Checkbox'
import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface SelectOrganisationUserListItemProps {
  organisation: Pick<OrganisationInterface, 'id' | 'name'>
  onSelectOrganisation: Dispatch<SetStateAction<number | null>>
  selectedOrganisation: number | null
  level: number
  childrenCount: number
}

export default function SelectOrganisationUserListItem({
  organisation,
  onSelectOrganisation,
  selectedOrganisation,
  level,
  childrenCount,
}: SelectOrganisationUserListItemProps) {
  const { id, name } = organisation
  const [isExpanded, setIsExpanded] = useState(false)
  const { fetchOrganisationChildren, organisation: orgWithChildren } = useLazyGetOrganisationChildren({
    organisationId: id,
  })

  const toggleAccordion = () => {
    if (!isExpanded && !orgWithChildren) {
      fetchOrganisationChildren()
    }
    setIsExpanded(!isExpanded)
  }

  return (
    <div data-testid="switch-organisation-option">
      <div
        style={{ paddingLeft: `${level * 2 + 2}rem` }}
        className="flex items-center justify-between gap-x-2 border-x border-t bg-gray-100 py-2 pr-3"
      >
        <div className="flex items-center gap-x-4">
          {childrenCount > 0 && (
            <div className="relative flex items-center" data-testid="parent-title">
              <div className="absolute left-[-1.6rem] text-gray-500">
                <ChevronDownIcon
                  className={classNames('ml-1 size-8 cursor-pointer rounded-full p-1 text-gray-500 transition', {
                    '-rotate-270 transform': isExpanded,
                    '-rotate-90 transform': !isExpanded,
                  })}
                  onClick={toggleAccordion}
                  data-testid="parent-dropdown"
                />
              </div>
            </div>
          )}
          <p
            onClick={() => onSelectOrganisation(id)}
            className={classNames('cursor-pointer', { 'ml-4': childrenCount === 0 })}
          >
            {name}
          </p>
        </div>
        <Checkbox
          isSelected={selectedOrganisation === id}
          onChange={() => onSelectOrganisation(id)}
          dataTestId="select-organisation-checkbox"
          aria-label="select-organisation-checkbox"
        />
      </div>
      {isExpanded &&
        !!orgWithChildren?.children?.unpaginated &&
        orgWithChildren?.children?.unpaginated.length > 0 &&
        orgWithChildren?.children?.unpaginated.map((childOrg) => (
          <SelectOrganisationUserListItem
            key={childOrg.id}
            organisation={{ id: childOrg.id, name: childOrg.name }}
            level={level + 1}
            onSelectOrganisation={onSelectOrganisation}
            selectedOrganisation={selectedOrganisation}
            childrenCount={childOrg.children?.totalCount || 0}
          />
        ))}
    </div>
  )
}
