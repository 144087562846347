import { Fragment, useState } from 'react'

import { TableWrapper } from '@/modules/shared/components/table/TableWrapper'
import useManageTablePreferences from '@/modules/shared/components/table/hooks/useManageTablePreferences'
import { TableProps } from '@/modules/shared/components/table/types'
import { classNames } from '@/modules/shared/utils/classNames'

export default function Table<T>({
  tableStyles,
  tableBodyStyles,
  tableHeadStyles,
  tableRowStyles,
  columns,
  columnsHidden,
  renderExtraLine,
  hasExtraLine,
  renderExtraLineSubLine,
  hasExtraLineSubLine,
  keyExtractor,
  dataSource,
  dataTestId,
  pagination,
  hideHeader,
  manageColumns,
  resizeColumns = false,
  tableName,
  showBottomPagination = true,
  showTopPagination = true,
  globalManageColumns = false,
  columnPreferenceModalOverlapping = true,
  getSubLines,
  onRowClick,
  isFirst,
  isLast,
  multiRowSelection,
  sortOptions,
  loading,
}: TableProps<T>) {
  const {
    filteredColumns,
    handleUpdateTablePreferences,
    loadingPreferences,
    updatingPreferences,
    savedSortPreferences,
    updateColumnWidth,
  } = useManageTablePreferences({
    tableName,
    columns,
    columnsHidden,
    manageColumns,
    globalManageColumns,
    getSubLines: !!getSubLines,
    sortOptions,
  })

  const [selectedRows, setSelectedRows] = useState<T[]>([])

  // Avoid flashing on the table
  if (loadingPreferences) return null

  return (
    <div>
      {(!getSubLines || isFirst) && showTopPagination && (
        <TableWrapper.Control
          columnPreferenceModalOverlapping={columnPreferenceModalOverlapping}
          pagination={pagination}
          defaultColumns={columns}
          manageColumns={manageColumns}
          filteredColumns={filteredColumns}
          globalManageColumns={globalManageColumns}
          onPreferencesChange={(filteredColumns, sortOption) =>
            handleUpdateTablePreferences(filteredColumns, sortOption)
          }
          updatingPreferences={updatingPreferences}
          sortOptions={sortOptions}
          savedSortPreferences={savedSortPreferences}
          top
          dataSource={dataSource}
        />
      )}
      {(multiRowSelection?.value || selectedRows).length > 0 && (
        <TableWrapper.MultiRowControl
          multiRowSelection={multiRowSelection}
          selectedRows={multiRowSelection?.value || selectedRows}
          setSelectedRows={multiRowSelection?.setValue || setSelectedRows}
        />
      )}
      <TableWrapper
        dataTestId={dataTestId}
        hasData={dataSource.length > 0}
        loading={pagination?.loading || loading}
        className={tableStyles}
        pagination={pagination}
      >
        <TableWrapper.Header
          resizeColumns={resizeColumns}
          updateColumnWidth={updateColumnWidth}
          hidden={hideHeader}
          className={tableHeadStyles}
          columns={filteredColumns}
          multiRowSelection={multiRowSelection}
          selectedRows={multiRowSelection?.value || selectedRows}
          setSelectedRows={multiRowSelection?.setValue || setSelectedRows}
          recordsPerPage={dataSource}
        />
        <tbody className={classNames(tableBodyStyles, 'border')}>
          {dataSource.map((record, index) => (
            <Fragment key={keyExtractor(record, index)}>
              <TableWrapper.Row
                record={record}
                index={index}
                columns={filteredColumns}
                className={tableRowStyles}
                onRowClick={onRowClick}
                selectedRows={multiRowSelection?.value || selectedRows}
                setSelectedRows={multiRowSelection?.setValue || setSelectedRows}
                multiRowSelection={multiRowSelection}
                hasExtraLine={hasExtraLine}
                getSubLines={getSubLines}
              />
              {getSubLines &&
                getSubLines(record).map((subLine, index) => (
                  <TableWrapper.SubLine
                    key={subLine.id + 'subLine'}
                    filteredColumns={filteredColumns.filter((column) => !column?.hidden || column?.alwaysVisible)}
                    subLine={subLine}
                    renderExtraLineSubLine={renderExtraLineSubLine}
                    hasExtraLineSubLine={hasExtraLineSubLine}
                    columns={columns}
                    index={index}
                    totalSubLines={getSubLines(record).length}
                  />
                ))}
              {getSubLines && getSubLines(record).length === 0 && (
                <TableWrapper.NoSubLines
                  filteredColumns={filteredColumns.filter((column) => !column?.hidden || column?.alwaysVisible)}
                />
              )}
              {renderExtraLine && hasExtraLine && hasExtraLine(record) && !getSubLines && (
                <tr className={classNames({ 'border-b': index !== dataSource.length - 1 })}>
                  <td colSpan={columns.length} className="space-y-2 px-4 pb-4">
                    {renderExtraLine(record, index)}
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </TableWrapper>
      {dataSource.length > 0 && showBottomPagination && pagination && (!getSubLines || isLast) && (
        <div className="mt-5">
          <TableWrapper.Control pagination={pagination} />
        </div>
      )}
    </div>
  )
}
