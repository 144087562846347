import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import CountBadge from '@/modules/shared/components/count-badge/CountBadge'
import { displayBadgeCount } from '@/modules/shared/components/count-badge/displayBadgeCount'
import { useGetDashboardStats } from '@/modules/shared/hooks/useGetDashboardStats'
import { BadgeIcon } from '@/modules/shared/icons-special/BadgeIcon'
import { CartIcon } from '@/modules/shared/icons/CartIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface RequisitionSideBarItemProps extends SideBarItem {
  requisitionRoutesMatch: boolean
}

export const RequisitionsSideBarItem = ({
  requisitionRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: RequisitionSideBarItemProps) => {
  const { t } = useTranslation()
  const { dashboardStats } = useGetDashboardStats()

  return (
    <Accordion
      titlePrefixIcon={
        <BadgeIcon isBadged={!!dashboardStats?.requisitionsAwaitingApprovalCount}>
          <CartIcon className="size-7" />
        </BadgeIcon>
      }
      title={t('general.requisitions', 'Requisitions')}
      buttonClassName="min-h-11 px-3"
      activeColor={requisitionRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 0}
      handleTriggerClick={() => changeOpenAccordionKey(0)}
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="requisition-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newRequisition', 'New Requisition'),
            target: '/requisitions/create',
            icon: <PlusIcon className="size-6 text-gray-500" />,
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/requisitions',
          },
          {
            key: t('general.awaitingMyApproval', 'Awaiting My Approval'),
            target: '/requisitions/awaiting-my-approval',
            icon: (
              <CountBadge displayText={displayBadgeCount(dashboardStats?.requisitionsAwaitingApprovalCount ?? 0, 25)} />
            ),
          },
          {
            key: t('general.myDrafts', 'My Drafts'),
            target: '/requisitions/drafts',
          },
        ]}
      />
    </Accordion>
  )
}
