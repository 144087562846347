import useSessionUser from '@/modules/access/hooks/useSessionUser'
import SupplierNavigation from '@/modules/shared/layouts/side-navigation/SupplierNavigation'
import PaperlessNavigation from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PaperlessNavigation'
import PurchaserNavigation from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'

export default function SideNavigation() {
  const { currentOrganisation } = useSessionUser()

  if (!currentOrganisation?.isAPurchaser) return <SupplierNavigation />

  if (currentOrganisation?.isAPurchaser) {
    if (!currentOrganisation?.featureFlags?.paperlessInvoicingOnly) return <PurchaserNavigation />
    if (currentOrganisation?.featureFlags?.paperlessInvoicingOnly) return <PaperlessNavigation />
  }
  return null
}
