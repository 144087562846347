import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import { UserIcon } from '@/modules/shared/icons/UserIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface UsersSideBarItemProps extends SideBarItem {
  usersRoutesMatch: boolean
}

export const UsersSideBarItem = ({
  usersRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: UsersSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (userPermissions?.CAN_MANAGE_USERS) {
      sideNavItems.push({
        key: t('general.newUser', 'New User'),
        target: '/users/create',
        icon: <PlusIcon className="size-6 text-gray-500" />,
      })
    }
    if (userPermissions?.CAN_VIEW_USERS) {
      sideNavItems.push({
        key: t('general.viewAll', 'View All'),
        target: '/users',
      })
    }
    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={<UserIcon className="size-7" />}
      title={t('general.users', 'Users')}
      activeColor={usersRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 11}
      handleTriggerClick={() => changeOpenAccordionKey(11)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="user-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
