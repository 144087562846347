import { ApolloLink, DefaultContext, NextLink, Operation } from '@apollo/client/core'

export default function createOperationNameLink(defaultUri: string, nodeEnv: string): ApolloLink {
  return new ApolloLink((operation: Operation, forward: NextLink) => {
    // Get the operation name from the operation
    const operationName = operation.operationName

    // Merge the existing context with the new one, appending the operation name to the URI
    operation.setContext((prevContext: DefaultContext) => {
      const uri = prevContext.uri || (prevContext.http && prevContext.http.uri) || defaultUri
      if (operationName && uri && nodeEnv !== 'production') {
        return {
          ...prevContext,
          uri: `${uri}?${encodeURIComponent(operationName)}`,
        }
      }
      return prevContext
    })

    // Continue to the next link in the chain
    return forward(operation)
  })
}
