import { classNames } from '@/modules/shared/utils/classNames'

interface ResizerProps {
  onDrag: (movementInRem: number) => void
  resizable?: boolean
}

export const Resizer = ({ onDrag, resizable }: ResizerProps) => {
  const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!resizable) return
    const startX = e.clientX
    const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const movementX = moveEvent.clientX - startX
      const movementInRem = movementX / baseFontSize
      onDrag(movementInRem)
    }

    const stopDragging = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', stopDragging)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', stopDragging)
  }

  return (
    <div
      onMouseDown={startDragging}
      data-testid="resizer"
      className={classNames(
        'float-right flex h-full w-[10px] select-none items-center justify-center',
        resizable && 'group cursor-col-resize'
      )}
    >
      {/* These divs are now conditionally visible based on hover state using TailwindCSS */}
      <div className="mr-[2px] h-6 w-[2px] bg-gray-300 opacity-0 group-hover:opacity-100" />
      <div className="h-full w-px bg-gray-300" />
      <div className="ml-[2px] h-6 w-[2px] bg-gray-300 opacity-0 group-hover:opacity-100" />
    </div>
  )
}
