import { useCallback, useRef, useState } from 'react'

import { Resizer } from '@/modules/shared/components/table/resizer/Resizer'
import { ColumnDefinition } from '@/modules/shared/components/table/types'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableHeaderCellProps<T> {
  updateColumnWidth: (column: ColumnDefinition<T>, width: number) => void
  column: ColumnDefinition<T>
  lastColumn: boolean
  resizeColumns?: boolean
}

export default function TableHeaderCell<T>({
  column,
  updateColumnWidth,
  lastColumn,
  resizeColumns,
}: TableHeaderCellProps<T>) {
  const { header } = column
  const FilterModalComponent = header?.FilterModalComponent
  const showActive = header?.showActive

  const [showModal, setShowModal] = useState(false)

  const lastWidthRef = useRef(column.minWidth || 0)
  const isColumnResizableRef = useRef(column.resizable === undefined ? true : column.resizable)

  const handleResize = useCallback(
    (movementInRem: number) => {
      if (!isColumnResizableRef.current) return
      // limit to two decimal places
      const newWidth = Math.floor(((column.minWidth || 0) + movementInRem) * 100) / 100
      if (lastWidthRef.current !== newWidth) {
        updateColumnWidth(column, newWidth)
        lastWidthRef.current = newWidth // Update the last width to the new width
      }
    },
    [column, updateColumnWidth]
  )

  if (!column.hidden || column.alwaysVisible) {
    return (
      <th scope="col" className={classNames(column?.headerCellStyles, 'relative whitespace-nowrap')}>
        <Tooltip content={header?.tooltip || ''}>
          <div
            className={classNames({
              'cursor-pointer rounded-full bg-gray-100 px-3 py-1 transition duration-200 hover:brightness-95':
                (FilterModalComponent || showActive) && !header?.active,
              'cursor-pointer rounded-full bg-primaryLighter px-3 py-1 text-primary transition duration-200 hover:brightness-95':
                (FilterModalComponent || showActive) && header?.active,
            })}
            data-testid={`header-${column?.key}`}
            onClick={() => {
              if (header?.onClick && !FilterModalComponent) {
                header.onClick()
              }

              if (FilterModalComponent) {
                setShowModal(true)
              }
            }}
          >
            {column?.title}
          </div>
        </Tooltip>
        {FilterModalComponent && <FilterModalComponent showModal={showModal} setShowModal={setShowModal} />}
        {!lastColumn && resizeColumns && (
          <div className="absolute inset-y-0 right-0 z-[1] mr-[-5px] w-[10px]">
            <Resizer onDrag={handleResize} resizable={isColumnResizableRef.current} />
          </div>
        )}
      </th>
    )
  }

  return null
}
