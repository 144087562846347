import i18next from 'i18next'

type sendFeedbackProps = {
  email: string | undefined
  message: string
  fullName: string | undefined
}

export async function sendFeedback({ email, message, fullName }: sendFeedbackProps): Promise<string> {
  const names = fullName?.split(' ')
  const firstName = names?.[0] || ''
  const lastName = names?.length || 0 > 1 ? names?.[names.length - 1] : ''

  const requestBody = {
    fields: [
      { name: 'email', value: email },
      { name: 'message', value: message },
      { name: 'firstname', value: firstName },
      { name: 'lastname', value: lastName },
    ],
  }

  const url = import.meta.env.VITE_APP_FEEDBACK_FORM_URL

  try {
    if (!email) {
      throw new Error('Email is required')
    }

    if (!url) {
      throw new Error('Destination URL is missing from environment variables')
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    return i18next.t('general.feedbackSubmittedSuccessfully', 'Feedback Submitted Successfully')
  } catch (error) {
    console.error(`Error: ${error}`)
    return i18next.t('sendFeedback.errorSubmittingForm', 'Feedback Submission Failed.')
  }
}
