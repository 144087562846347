import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { CatalogIcon } from '@/modules/shared/icons/CatalogIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface CatalogsSideBarItemProps extends SideBarItem {
  catalogRoutesMatch: boolean
}

export const CatalogsSideBarItem = ({
  catalogRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: CatalogsSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (userPermissions?.PURCHASER_CAN_MANAGE_SM_CATALOGS) {
      sideNavItems.push({
        key: t('general.newCatalog', 'New Catalog'),
        target: '/catalogs/create',
        icon: <PlusIcon className="size-6 text-gray-500" />,
      })
    }
    sideNavItems.push({
      key: t('general.viewAll', 'View All'),
      target: '/catalogs',
    })
    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={<CatalogIcon className="size-7" />}
      title={t('general.catalogs', 'Catalogs')}
      activeColor={catalogRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 4}
      handleTriggerClick={() => changeOpenAccordionKey(4)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="catalogs-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
