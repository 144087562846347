import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetRequisitionOriginTypeDocument } from '@/graphql/purchasing/generated/getRequisitionOriginType.generated'
import { OriginType } from '@/modules/requisitions/types/OriginType'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export function useGetRequisitionOriginType() {
  const { requisitionId } = useParams()
  const { data, loading } = useQuery(GetRequisitionOriginTypeDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    skip: !requisitionId,
  })

  const originType = data?.currentPurchaser?.requisition?.originType || ''
  const originId = data?.currentPurchaser?.requisition?.originId || ''
  const isCustomRequisition = !originType && !loading
  const isAllSupplierRequisition = originType === OriginType.Purchaser && !loading
  const isSingleSupplierRequisition = originType === OriginType.SupplierRelationship && !loading
  const isBuyListRequisition = originType === OriginType.Catalogue && !loading
  const isReOrderRequisition = originType === OriginType.Requisition && !loading

  return {
    originType,
    originId,
    isCustomRequisition,
    isAllSupplierRequisition,
    isSingleSupplierRequisition,
    isBuyListRequisition,
    isReOrderRequisition,
  }
}
