import { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ZodIssueCode } from 'zod'

import { Category, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import CatAndSubCat from '@/modules/suppliers/pages/supplier/general/settings/form-elements/CatAndSubCat'

interface CreateProductInformationProps {
  setSelectedCategory: Dispatch<SetStateAction<Category | null>>
  defaultProduct?: Product
}

export default function CreateProductInformation({
  setSelectedCategory,
  defaultProduct,
}: CreateProductInformationProps) {
  const { t } = useTranslation()
  const { formState } = useFormContext()
  const { currentOrganisation } = useSessionUser()
  return (
    <>
      <CatAndSubCat
        title={t('createProduct.categoryTitle', 'Product Category and Sub-Category')}
        description={t(
          'createProduct.categoryDescription',
          'The product Category is required, you must assign a Category and Sub-Category to the product you are creating.'
        )}
        register="categoryId"
        setCategoryObject={setSelectedCategory}
        defaultCategory={defaultProduct?.category || undefined}
      />
      {formState.errors.categoryId && formState.errors.categoryId.type === ZodIssueCode.invalid_type && (
        <span className="mt-2 block text-sm text-error" role="alert">
          {t('createProduct.categoryRequiredMsg', 'You must provide a Category and Sub-Category.')}
        </span>
      )}
      <FormInput
        title={t('general.brand', 'Brand')}
        description={t(
          'createProduct.brandDescription',
          'Provide a Brand for the product if applicable, the Brand is optional.'
        )}
        register="brand"
        placeholder={t('general.brand', 'Brand')}
        className="mt-8"
      />
      <FormInput
        title={t('general.productName', 'Product Name')}
        description={t(
          'createProduct.productNameDescription',
          'Provide a name for the product using this field, a name is required.'
        )}
        register="itemDescription"
        requiredError={t('createProduct.productNameError', 'You must provide a Product Name.')}
        placeholder={t('general.productName', 'Product Name')}
        className="mt-8"
      />
      {currentOrganisation?.isAPurchaser && (
        <FormInput
          title={t('general.purchaserCode', 'Purchaser Code')}
          description={
            <Trans i18nKey="createProduct.purchaserCodeDescription">
              The Purchaser Code is the code that your organisation uses to search for and identify this product. The
              Purchaser Code is optional and does not need to be unique, although we recommend that it is kept unique.
              The Purchaser Code will only be seen by users at your organisation.
            </Trans>
          }
          register="code"
          placeholder={t('general.purchaserCode', 'Purchaser Code')}
          className="mt-8"
        />
      )}
      {!currentOrganisation?.isAPurchaser && (
        <FormInput
          title={t('general.supplierCode', 'Supplier Code')}
          description={
            <Trans i18nKey="createProduct.supplierCodeDescription">
              The Supplier Code is the code that your organisation uses to search for and identify this product. The
              Supplier Code is optional and does not need to be unique, although we recommend that it is kept unique.
              The Supplier Code will be seen by users at your organisation, and any Purchaser who you allow to view this
              product when placing it in a Catalog.
            </Trans>
          }
          register="code"
          placeholder={t('general.supplierCode', 'Supplier Code')}
          className="mt-8"
        />
      )}
      <FormInput
        title={t('general.externalId', 'External ID')}
        description={t(
          'createStockItem.externalIdDescription',
          'The External ID can be important for integrating PurchasePlus with an external system. The External ID is the unique identifier that may be used to identify this product in other systems at your Organisation. The External ID is optional and does not need to be unique, although we recommend that it is kept unique.'
        )}
        register="externalId"
        placeholder={t('general.externalId', 'External ID')}
        className="mt-8"
      />
    </>
  )
}
