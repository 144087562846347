import { WatchQueryFetchPolicy, useLazyQuery } from '@apollo/client'

import { GetSupplierRelationshipsDocument } from '@/graphql/purchasing/generated/getSupplierRelationships.generated'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface UseGetLazyMySuppliersProps {
  fetchPolicy: WatchQueryFetchPolicy
}

export function useGetLazyMySuppliers({ fetchPolicy }: UseGetLazyMySuppliersProps) {
  const [getLazyMySuppliers, { loading }] = useLazyQuery(GetSupplierRelationshipsDocument, {
    fetchPolicy: fetchPolicy,
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  return { getLazyMySuppliers, loading }
}
