import BrokenImage from '@/assets/images/broken-image.svg'
import NoImage from '@/assets/images/no-image.svg'

interface ImageItemProps {
  src: string | null | undefined
  alt: string
  className?: string
  onClick?: () => void
}

export const ImageItem = ({ src, alt, className, onClick }: ImageItemProps) => (
  <div className={className} onClick={onClick}>
    <img
      className="size-full"
      src={src || NoImage}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement
        target.onerror = null
        target.src = BrokenImage
      }}
      alt={alt}
    />
  </div>
)
