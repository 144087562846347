import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import useFindMatchSupplierRoutes from '@/modules/shared/hooks/useFindMatchSupplierRoutes'
import { HomeIcon } from '@/modules/shared/icons/HomeIcon'
import { PurchaserIcon } from '@/modules/shared/icons/PurchaserIcon'
import { SideBarContext } from '@/modules/shared/layouts/app-layout/SideBarContext'
import SwitchOrganisationModal from '@/modules/shared/layouts/header/switch-organisation-modal/SwitchOrganisationModal'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { AccountSideBarItems } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/AccountSideBarItems'
import { ProductSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/ProductSideBarItem'
import { UsersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/UsersSideBarItem'
import { Themes } from '@/modules/shared/types/Themes'
import { classNames } from '@/modules/shared/utils/classNames'

export default function SupplierNavigation() {
  const sideBar = useContext(SideBarContext)

  const { t } = useTranslation()

  const { accordionDefaultKey, myProductsRoutes, purchasersRoutes, usersRoutesMatch } = useFindMatchSupplierRoutes()

  const [openSwitchOrg, setOpenSwitchOrg] = useState(false)
  const [openAccordionKey, setOpenAccordionKey] = useState<number | undefined>()
  const { currentOrganisation } = useSessionUser()
  const { featureFlags } = currentOrganisation || {}
  const { userPermissions } = useSessionUser()

  useEffect(() => {
    if (accordionDefaultKey) {
      setOpenAccordionKey(accordionDefaultKey)
    }
  }, [accordionDefaultKey])

  const changeOpenAccordionKey = (key: number | undefined) => {
    if (key === openAccordionKey) {
      setOpenAccordionKey(undefined)
    } else {
      setOpenAccordionKey(key)
    }
  }

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <>
      <nav className="flex-1 pb-1 text-sm" data-testid="side-navigation-items">
        <section className="space-y-1.5">
          <NavLink
            data-testid="home-navigation-link"
            to="/dashboard"
            onClick={() => onCloseDrawer()}
            className={({ isActive }) => {
              return classNames(
                'flex h-11 items-center gap-x-2 rounded-md  px-3  transition duration-200 hover:brightness-95',
                {
                  'bg-primary text-white': isActive,
                  'bg-gray-200': !isActive,
                }
              )
            }}
          >
            <HomeIcon className="size-7" />
            {t('general.home', 'Home')}
          </NavLink>
          {featureFlags?.supplierShowProductsTab && (
            <ProductSideBarItem
              myProductRoutesMatch={myProductsRoutes}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              isSupplier
            />
          )}
          {featureFlags?.supplierShowPurchasersTab && (
            <Accordion
              titlePrefixIcon={<PurchaserIcon className="size-7" />}
              title={t('general.purchasers', 'Purchasers')}
              buttonClassName="h-11 px-3"
              activeColor={purchasersRoutes ? Themes.Primary : undefined}
              defaultOpen={openAccordionKey === 1}
              handleTriggerClick={() => changeOpenAccordionKey(1)}
              keepActiveColor
            >
              <SideNavLink
                onCloseDrawer={() => onCloseDrawer()}
                testId="purchaser-directory-navigation"
                sideNavLinkItems={[
                  {
                    key: t('general.purchaserDirectory', 'Purchaser Directory'),
                    target: '/purchaser-directory',
                  },
                ]}
              />
            </Accordion>
          )}
          {(userPermissions?.CAN_VIEW_USERS || userPermissions?.CAN_MANAGE_USERS) && (
            <UsersSideBarItem
              usersRoutesMatch={usersRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
            />
          )}
        </section>
        <AccountSideBarItems
          openAccordionKey={openAccordionKey}
          changeOpenAccordionKey={changeOpenAccordionKey}
          onCloseDrawer={onCloseDrawer}
        />
      </nav>
      <SwitchOrganisationModal showModal={openSwitchOrg} setShowModal={setOpenSwitchOrg} />
    </>
  )
}
