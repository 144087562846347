import { z } from 'zod'

import * as formErrorMessages from '@/modules/shared/utils/formErrorMessages'

const { tooLongString, feedbackIsEmpty } = formErrorMessages

export const FeedbackModalFormSchema = z.object({
  message: z.string().max(2000, tooLongString(2000)).min(1, feedbackIsEmpty()),
})

export type FeedbackModalFormInputs = z.infer<typeof FeedbackModalFormSchema>
