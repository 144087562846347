import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SupplierRelationship } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetInvoice } from '@/modules/invoices/hooks/useGetInvoice'
import { useGetRequisitionOriginType } from '@/modules/requisitions/hooks/useGetRequisitionOriginType'
import FormSelectSupplierRelationship from '@/modules/shared/components/create-form/FormSelectSupplierRelationship'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import DeptAndAccount from '@/modules/shared/components/form-elements/DeptAndAccount'
import NumberInput from '@/modules/shared/components/number-input/NumberInput'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { ProductListType } from '@/modules/shared/types/ProductListType'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { useGetLazyMySuppliers } from '@/modules/suppliers/hooks/useGetLazyMySuppliers'

interface CreateProductValuesProps {
  type: ProductListType
  defaultUnitPrice?: number
  defaultTaxPercentage?: number
}

export default function CreateProductValues({
  type,
  defaultUnitPrice,
  defaultTaxPercentage,
}: CreateProductValuesProps) {
  const { t } = useTranslation()
  const formMethods = useFormContext<CreateProductFormInputs>()

  const { currency } = useMoney()
  const { isSingleSupplierRequisition, originId } = useGetRequisitionOriginType()

  const { getLazyMySuppliers } = useGetLazyMySuppliers({ fetchPolicy: 'no-cache' })
  const { department, account } = useGetInvoice()

  const errorQuantity = !!formMethods.formState.errors.quantity
  const errorUnitPrice = !!formMethods.formState.errors.unitPrice
  const errorTaxPercentage = !!formMethods.formState.errors.taxPercentage

  useEffect(() => {
    if (type === ProductListType.AddRequisitionProduct && isSingleSupplierRequisition) {
      getLazyMySuppliers({
        variables: {
          filter: {
            q: [
              {
                property: 'id_eq',
                value: originId,
              },
            ],
          },
        },
        onCompleted(data) {
          formMethods.setValue(
            'supplierId',
            extractEdges<SupplierRelationship>(data.currentPurchaser?.supplierRelationships)[0].supplierId || 0
          )
        },
      })
    }
  }, [isSingleSupplierRequisition])

  useEffect(() => {
    if (department && account && type === ProductListType.AddInvoiceLine) {
      formMethods.setValue('departmentId', department.id)
      formMethods.setValue('accountId', account.id)
    }
  }, [department, account, type])

  const getQuantityText = () => {
    switch (type) {
      case ProductListType.AddInvoiceLine:
        return t('invoices.addLine.modal.quantityDescription', 'Set the quantity for this product on the Invoice line.')
      case ProductListType.AddReceivingNoteProduct:
        return t(
          'receivingNote.addProduct.modal.quantityDescription',
          'Set the quantity of this product that you wish to receive on this Receiving Note.'
        )
      case ProductListType.AddRequisitionProduct:
        return t(
          'requisition.customPR.productForms.quantityDesc',
          'Set the quantity of this product that you wish to order from the Supplier in this Requisition.'
        )
      default:
        return ''
    }
  }

  const getUnitPriceText = () => {
    switch (type) {
      case ProductListType.AddCatalogProduct:
        return t(
          'createProductModal.unitPriceDescription.catalog',
          'Set the price for this product in this catalog. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
        )
      case ProductListType.AddInvoiceLine:
        return t(
          'invoices.addLine.modal.unitPriceDescription.invoice',
          'Set the price for this product on Invoice line. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
        )
      case ProductListType.AddReceivingNoteProduct:
        return t(
          'receivingNote.addProduct.modal.unitPriceDescription',
          'Set the Receiving Unit Price for this product on this Receiving Note. The Receiving Unit Price is exclusive of tax.'
        )
      case ProductListType.AddRequisitionProduct:
        return t(
          'requisition.customPR.productForms.unitPriceDesc',
          'Set the price for this product in the Requisition. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
        )
      default:
        return ''
    }
  }

  const getTaxText = () => {
    switch (type) {
      case ProductListType.AddCatalogProduct:
        return t(
          'createProductModal.taxPercentageDescription.catalog',
          'Set the tax percentage for this product in this catalog. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
        )
      case ProductListType.AddInvoiceLine:
        return t(
          'invoices.addLine.modal.taxPercentageDescription.invoice',
          'Set the tax percentage for this product on the Invoice line. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
        )
      case ProductListType.AddRequisitionProduct:
        return t(
          'requisition.customPR.productForms.taxPercentageDesc',
          'Set the tax percentage for this product in the Requisition. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
        )
      default:
        return ''
    }
  }

  return (
    <>
      {type === ProductListType.AddRequisitionProduct && !isSingleSupplierRequisition && (
        <FormSelectSupplierRelationship
          className="mt-3"
          title={t('general.selectASupplier', 'Select a Supplier')}
          description={t(
            'requisition.customPR.productForms.supplierDesc',
            'Select a Supplier from whom you wish to order this product.'
          )}
          placeholder={t('general.selectASupplierDots', 'Select a Supplier...')}
          register="supplierId"
          required
          requiredError={t('general.supplierRequiredMsg', 'You must select a Supplier.')}
        />
      )}
      {type === ProductListType.AddInvoiceLine && (
        <DeptAndAccount
          className="mt-3"
          title={t('general.departmentAndAccountCode', 'Department and Account Code')}
          description={t(
            'invoice.productForms.departmentAndAccountCodeDesc',
            'Select a Department then an Account code to record this Invoice Line against. Your organisation uses Department and Account Codes for recording Invoice expenses.'
          )}
          required
          defaultAccountValue={
            account ? { id: Number(account?.id), accountName: account?.accountName || '' } : undefined
          }
          defaultDepartmentValue={department ? { id: Number(department?.id), name: department?.name || '' } : undefined}
        />
      )}
      {(type === ProductListType.AddInvoiceLine ||
        type === ProductListType.AddReceivingNoteProduct ||
        type === ProductListType.AddRequisitionProduct) && (
        <div>
          <p className="font-bold">{t('general.quantity', 'Quantity')}</p>
          <p className="text-gray-500">{getQuantityText()}</p>
          <Controller
            control={formMethods.control}
            name="quantity"
            rules={{ required: true }}
            render={({ field }) => (
              <NumberInput
                className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                leftUnit={t('general.qty', 'QTY')}
                data-testid="quantity-input"
                aria-label={t('general.quantity', 'Quantity')}
                hasError={errorQuantity}
                errorMessage={t('invoices.addLine.modal.quantityRequiredMsg', 'You must provide a Quantity.')}
                defaultValue={0}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </div>
      )}
      {(type === ProductListType.AddCatalogProduct ||
        type === ProductListType.AddInvoiceLine ||
        type === ProductListType.AddReceivingNoteProduct ||
        type === ProductListType.AddRequisitionProduct) && (
        <div>
          <p className="font-bold">{t('general.unitPrice', 'Unit Price')}</p>
          <p className="text-gray-500">{getUnitPriceText()}</p>
          <Controller
            control={formMethods.control}
            name="unitPrice"
            rules={{ required: true }}
            render={({ field }) => (
              <NumberInput
                className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                leftUnit={currency}
                data-testid="price-input"
                aria-label={t('general.unitPrice', 'Unit Price')}
                formatOptions={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }}
                hasError={errorUnitPrice}
                errorMessage={t('invoices.addLine.modal.unitPriceRequiredMsg', 'You must provide a Unit Price.')}
                defaultValue={defaultUnitPrice || 0}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </div>
      )}
      {(type === ProductListType.AddCatalogProduct ||
        type === ProductListType.AddInvoiceLine ||
        type === ProductListType.AddRequisitionProduct) && (
        <div>
          <p className="font-bold">{t('general.taxPercentage', 'Tax Percentage')}</p>
          <p className="text-gray-500">{getTaxText()}</p>
          <Controller
            control={formMethods.control}
            name="taxPercentage"
            render={({ field }) => (
              <NumberInput
                className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                leftUnit={t('general.taxWithPercentage', 'Tax %')}
                data-testid="tax-input"
                aria-label={t('general.taxPercentage', 'Tax Percentage')}
                hasError={errorTaxPercentage}
                errorMessage={t('invoices.addLine.modal.taxPercentageErrorMsg', 'You must provide a Tax Percentage.')}
                defaultValue={defaultTaxPercentage || 0}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </div>
      )}
    </>
  )
}
