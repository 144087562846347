import { Controller, useFormContext } from 'react-hook-form'

import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import { packNameValues } from '@/modules/shared/components/pack-name-dropdown/PackNameValues'

interface PackNameDropdownProps {
  disabled?: boolean
  sellPackName?: boolean
  defaultValue?: string | null
  resetToDefault?: boolean
  onChange?: (value: string) => void
  floating?: boolean
}

export default function PackNameDropdown({
  disabled,
  sellPackName,
  defaultValue,
  resetToDefault,
  onChange,
  floating,
}: PackNameDropdownProps) {
  const formMethods = useFormContext()

  return (
    <Controller
      control={formMethods.control}
      name={sellPackName ? 'itemSellPackName' : 'itemPackName'}
      render={({ field }) => (
        <ComboboxClient
          items={packNameValues}
          keyExtractor={(e) => e.key}
          defaultValue={packNameValues.find(
            (packName) => packName.value === ((!resetToDefault && defaultValue) || (sellPackName ? 'each' : '--'))
          )}
          keyFilter="value"
          onSelected={(e) => (onChange ? onChange(e.key) : field.onChange(e.key))}
          disabled={disabled}
          testId="packName"
          floating={floating}
        ></ComboboxClient>
      )}
    />
  )
}
