import { useRef } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'

import { ThemeWrapper } from '@/modules/shared/components/theme-wrapper/ThemeWrapper'
import { CloseIcon } from '@/modules/shared/icons/CloseIcon'
import { Themes } from '@/modules/shared/types/Themes'

interface ChipProps {
  label: string
  onRemoveChip: () => void
  theme?: Themes
  testId?: string
}

export function Chip(props: AriaButtonProps & ChipProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <ThemeWrapper
      className="flex w-fit items-center gap-x-2 rounded-md px-3 py-2"
      theme={props.theme || Themes.Primary}
      data-testid={props.testId || 'chip'}
    >
      <span>{props.label}</span>
      <button
        {...buttonProps}
        ref={ref}
        onClick={props.onRemoveChip}
        className="rounded-sm border-none focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1"
        data-testid="remove-chip"
      >
        <CloseIcon className="size-5" />
      </button>
    </ThemeWrapper>
  )
}
