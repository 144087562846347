import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components/button/Button'
import { Checkbox } from '@/modules/shared/components/checkbox/Checkbox'
import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { ColumnDefinition, SortOption } from '@/modules/shared/components/table/types'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'

export interface ManageTablePreferencesModalProps<T> {
  showModal: boolean
  setShowModal: (value: false) => void
  filteredColumns: ColumnDefinition<T>[]
  onPreferencesChange: (filteredColumns: ColumnDefinition<T>[], sortOption: SortOption) => void
  updatingPreferences: boolean
  defaultColumns?: ColumnDefinition<T>[]
  sortOptions?: SortOption[]
  savedSortPreferences: SortOption
}

export default function ManageTablePreferencesModal<T>({
  showModal,
  setShowModal,
  filteredColumns,
  onPreferencesChange,
  updatingPreferences,
  defaultColumns,
  sortOptions,
  savedSortPreferences,
}: ManageTablePreferencesModalProps<T>) {
  const { t } = useTranslation()

  const [updatedColumns, setUpdatedColumns] = useState<ColumnDefinition<T>[]>(filteredColumns)
  const [updatedSorting, setUpdatedSorting] = useState<SortOption>(savedSortPreferences)

  sortOptions = sortOptions?.filter((option) => !option.disabled)

  useEffect(() => {
    if (showModal) {
      // update the checkboxes/combobox when the modal is shown, was retaining dirty state when closing and reopening without saving
      setUpdatedColumns(filteredColumns)
      setUpdatedSorting(savedSortPreferences)
    }
  }, [showModal])

  useEffect(() => {
    if (!updatingPreferences) {
      onCloseModal()
    }
  }, [updatingPreferences])

  const onCloseModal = () => {
    setShowModal(false)
  }

  const handleCheckboxChange = (key: string) => {
    setUpdatedColumns((prevPreferences) =>
      prevPreferences.map((col) => (col.key === key ? { ...col, hidden: !col.hidden } : col))
    )
  }

  const handleSave = () => {
    onPreferencesChange(updatedColumns || filteredColumns, updatedSorting || savedSortPreferences)
  }

  const handleRestoreDefaults = () => {
    const defaultSorting = sortOptions?.find((sortOptions) => sortOptions.isDefault)
    setUpdatedColumns(defaultColumns || filteredColumns)
    setUpdatedSorting(defaultSorting || savedSortPreferences)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title
          title={t('general.manageColumnPreferences', 'Manage Column Preferences')}
          onCloseModal={onCloseModal}
        />
        <Modal.Body className="space-y-5 overflow-y-auto" data-testid="manage-col-modal">
          <div className="text-sm" data-testid="sorting-preferences">
            <p className="font-bold">{t('general.sortingPreferences', 'Sorting Preferences')}</p>
            <p className="text-gray-500">
              <Trans i18nKey="manageTablePreferences.sortingPreferences.description">
                Here you can manage your column sort preference for this table view. The sort preference will be saved
                to your profile and will be applied to this view each time you visit it.
              </Trans>
            </p>
            <div className="mt-2" key={`${updatedSorting?.key}_${updatedSorting?.direction}` || ''}>
              <ComboboxClient
                testId="sort-options"
                items={sortOptions || []}
                defaultValue={updatedSorting}
                keyExtractor={(e) => `${e?.key}_${e?.direction}`}
                keyFilter="name"
                disabled={!sortOptions?.length}
                placeholder={t('general.sortingNotAvailable', 'Sorting Not Available')}
                onSelected={(e) => setUpdatedSorting(e)}
              />
            </div>
          </div>
          <div className="text-sm" data-testid="columns-preferences">
            <p className="font-bold">{t('general.columnPreferences', 'Column Preferences')}</p>
            <p className="text-gray-500">
              <Trans i18nKey="manageTablePreferences.columnPreferences.description">
                Here you can manage your column preferences for this table. These preferences will be saved onto your
                account and they will be applied to this view each time you visit it. You can decide which columns you
                wish to show or hide. For technical reasons there are some columns that cannot be hidden.
              </Trans>
            </p>

            <div className="mt-2 flex w-full justify-between">
              <div className="w-max px-4 py-3 text-left text-sm text-gray-500">
                {t('general.columnName', 'Column Name')}
              </div>
              <div className="w-max px-6 py-3 text-right text-sm text-gray-500">{t('general.show', 'Show')}</div>
            </div>
            <div className="w-full rounded-md border">
              <table className="w-full divide-y">
                <tbody className="divide-y divide-gray-200">
                  {updatedColumns?.map(({ key, title, hidden, header, grow, alwaysVisible }: ColumnDefinition<T>) => (
                    <tr key={key} className="items-center bg-gray-100 p-2">
                      <td className="px-4 py-3">
                        <div className="flex grow flex-row items-center justify-start gap-x-1.5 text-center">
                          {header?.tooltip ? (
                            <span className="flex items-center gap-x-1">
                              {title}
                              <Tooltip content={header.tooltip}>
                                <QuestionMarkIcon className="size-4" />
                              </Tooltip>
                            </span>
                          ) : (
                            title
                          )}
                        </div>
                      </td>
                      <td className="flex justify-end px-4 py-3 text-end">
                        <div className="w-10" data-testid="show-column-checkbox">
                          <Checkbox
                            isSelected={!hidden}
                            aria-label={title}
                            onChange={() => handleCheckboxChange(key)}
                            disabled={grow || alwaysVisible}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row md:justify-between">
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={handleRestoreDefaults}>
            {t('general.restoreDefaults', 'Restore Defaults')}
          </Button>
          <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
            <Button
              className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
              onClick={onCloseModal}
              data-testid="cancel-manage-col-modal"
            >
              {t('general.cancel', 'Cancel')}
            </Button>

            <Button
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              onClick={handleSave}
              data-testid="submit-manage-col-modal"
              loading={updatingPreferences}
            >
              {updatingPreferences
                ? t('general.savingChanges', 'Saving Changes')
                : t('general.saveChanges', 'Save Changes')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
