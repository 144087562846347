import useSessionUser from '@/modules/access/hooks/useSessionUser'

interface ElementSwitchProps {
  aStoreElement: JSX.Element
  defaultElement: JSX.Element
}

export function ElementSwitch(props: ElementSwitchProps) {
  const { currentOrganisation } = useSessionUser()

  if (currentOrganisation?.ppxAccess) return props.aStoreElement

  return props.defaultElement
}
