import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetProductBarcodesDocument } from '@/graphql/purchase-plus/generated/getProductBarcodes.generated'
import { Barcode } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

interface UseGetProductBarcodesProps {
  productIdProp?: string
  skip?: boolean
}

export function useGetProductBarcodes(args?: UseGetProductBarcodesProps) {
  const { productIdProp, skip } = args || {}
  const { productId: productIdFromParams } = useParams<{ productId: string }>()

  const productId = productIdProp || productIdFromParams
  const { data, networkStatus, error } = useQuery(GetProductBarcodesDocument, {
    variables: {
      productId: Number(productId),
    },
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    skip: !productId || skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  })

  const loadingStates = checkNetworkStatus(networkStatus)
  return {
    error,
    barcodes: extractEdges<Barcode>(data?.product?.barcodes),
    loadingStates,
  }
}
