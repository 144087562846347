export const packNameValues = [
  { key: 'null', value: '--' },
  { key: 'bag', value: 'bag' },
  { key: 'bale', value: 'bale' },
  { key: 'bar', value: 'bar' },
  { key: 'barrel', value: 'barrel' },
  { key: 'basket', value: 'basket' },
  { key: 'bib', value: 'bib' },
  { key: 'bin', value: 'bin' },
  { key: 'bladder', value: 'bladder' },
  { key: 'block', value: 'block' },
  { key: 'book', value: 'book' },
  { key: 'bottle', value: 'bottle' },
  { key: 'bowl', value: 'bowl' },
  { key: 'box', value: 'box' },
  { key: 'brick', value: 'brick' },
  { key: 'brochure', value: 'brochure' },
  { key: 'bucket', value: 'bucket' },
  { key: 'bunch', value: 'bunch' },
  { key: 'bundle', value: 'bundle' },
  { key: 'can', value: 'can' },
  { key: 'canister', value: 'canister' },
  { key: 'capsule', value: 'capsule' },
  { key: 'carton', value: 'carton' },
  { key: 'cartridge', value: 'cartridge' },
  { key: 'case', value: 'case' },
  { key: 'cask', value: 'cask' },
  { key: 'catering_pack', value: 'catering_pack' },
  { key: 'cone', value: 'cone' },
  { key: 'container', value: 'container' },
  { key: 'crate', value: 'crate' },
  { key: 'cube', value: 'cube' },
  { key: 'cup', value: 'cup' },
  { key: 'cylinder', value: 'cylinder' },
  { key: 'dish', value: 'dish' },
  { key: 'dispenser', value: 'dispenser' },
  { key: 'drum', value: 'drum' },
  { key: 'each', value: 'each' },
  { key: 'fillet', value: 'fillet' },
  { key: 'flagon', value: 'flagon' },
  { key: 'flask', value: 'flask' },
  { key: 'glass jar', value: 'glass jar' },
  { key: 'inner', value: 'inner' },
  { key: 'jar', value: 'jar' },
  { key: 'jerry_can', value: 'jerry_can' },
  { key: 'keg', value: 'keg' },
  { key: 'layer', value: 'layer' },
  { key: 'leg', value: 'leg' },
  { key: 'length', value: 'length' },
  { key: 'loaf', value: 'loaf' },
  { key: 'log', value: 'log' },
  { key: 'packet', value: 'packet' },
  { key: 'pack', value: 'pack' },
  { key: 'pair', value: 'pair' },
  { key: 'pallet', value: 'pallet' },
  { key: 'pc', value: 'pc' },
  { key: 'per', value: 'per' },
  { key: 'piece', value: 'piece' },
  { key: 'plastic jar', value: 'plastic jar' },
  { key: 'portion', value: 'portion' },
  { key: 'punnet', value: 'punnet' },
  { key: 'rack', value: 'rack' },
  { key: 'ream', value: 'ream' },
  { key: 'roll', value: 'roll' },
  { key: 'sack', value: 'sack' },
  { key: 'serve', value: 'serve' },
  { key: 'slab', value: 'slab' },
  { key: 'slice', value: 'slice' },
  { key: 'spool', value: 'spool' },
  { key: 'tablet', value: 'tablet' },
  { key: 'tin', value: 'tin' },
  { key: 'tray', value: 'tray' },
  { key: 'tub', value: 'tub' },
  { key: 'tube', value: 'tube' },
  { key: 'whole', value: 'whole' },
]
