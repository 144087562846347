import { arrow, autoUpdate, flip, offset, useFloating } from '@floating-ui/react-dom'
import { PropsWithChildren, useRef } from 'react'
import { TooltipTriggerProps, mergeProps, useTooltipTrigger } from 'react-aria'
import { useTooltipTriggerState } from 'react-stately'

import { Portal } from '@/modules/shared/layouts/Portal'

interface TooltipProps extends PropsWithChildren<TooltipTriggerProps> {
  content: string | JSX.Element
  showArrow?: boolean
}

const TOOLTIP_WIDTH = 200

export function Tooltip({ showArrow = true, content, ...rest }: TooltipProps) {
  const arrowRef = useRef(null)
  const ref = useRef(null)
  const shiftRef = useRef(0)
  const state = useTooltipTriggerState({ ...rest, delay: rest.delay ?? 300 }) // default delay is 300ms
  const { triggerProps, tooltipProps } = useTooltipTrigger(rest, state, ref)
  const ARROW_SIZE = 8
  const {
    x,
    y,
    strategy,
    placement,
    refs: { setReference, setFloating },
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(ARROW_SIZE / Math.sqrt(2)), flip(), arrow({ element: arrowRef })],
  })

  const shiftAmount = Math.max(0, Math.min(x || 0, window.innerWidth - TOOLTIP_WIDTH))
  shiftRef.current = shiftAmount - (x || 0)

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]]

  return (
    <span className="relative inline-block cursor-pointer" ref={setReference} {...triggerProps}>
      {rest.children}
      {state.isOpen && content && (
        <Portal>
          {typeof window !== 'undefined' && ( // Check if window is defined
            <div
              {...mergeProps(rest, tooltipProps)}
              className="z-50 max-w-[11.25rem] break-words rounded-md bg-gray-800 p-2 text-center text-xxs text-white"
              ref={setFloating}
              style={{
                position: strategy,
                top: (y || 0) - 2,
                left: shiftAmount,
                width: `${TOOLTIP_WIDTH}px`, // Fixed width
              }}
            >
              {showArrow && staticSide && (
                <div
                  className="absolute rotate-45 bg-gray-800"
                  ref={arrowRef}
                  style={{
                    width: `${ARROW_SIZE}px`,
                    height: `${ARROW_SIZE}px`,
                    left: arrowX != null ? `${Math.min(arrowX - shiftRef.current, 165)}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    right: '',
                    bottom: '',
                    [staticSide]: `-${ARROW_SIZE / 2}px`,
                  }}
                />
              )}
              {content}
            </div>
          )}
        </Portal>
      )}
    </span>
  )
}
