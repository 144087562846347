import { Maybe } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DEFAULT_CONCATENATED_SELL_UNIT } from '@/modules/requisitions/constants'
import { titleCase } from '@/modules/shared/utils/titleCase'

export type Input = {
  itemSize: Maybe<number>
  itemMeasure: Maybe<string>
  itemSellUnit: Maybe<string>
  itemPackName: Maybe<string>
}

export function formatProductItem(input: Input): string {
  let { itemMeasure, itemPackName, itemSellUnit } = input
  const { itemSize } = input
  let result = ''

  const itemSizeString = itemSize ?? ''
  itemMeasure = itemMeasure ?? ''
  itemPackName = itemPackName ?? ''
  itemSellUnit = itemSellUnit ?? ''

  if (itemSize === 1 && itemMeasure === 'each' && itemSellUnit === DEFAULT_CONCATENATED_SELL_UNIT && !itemPackName) {
    result = titleCase(DEFAULT_CONCATENATED_SELL_UNIT)
  } else {
    result = `${itemSizeString} ${itemMeasure} ${titleCase(itemPackName || '')} ${
      (itemSize || itemMeasure || itemPackName) && '|'
    } ${titleCase(itemSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}`
  }
  return result.replace(/\s+/g, ' ').trim()
}

export function formatStockItem(input: Omit<Input, 'itemSellUnit'>): string {
  let { itemMeasure, itemPackName } = input
  const { itemSize } = input
  let result = ''

  const itemSizeString = itemSize ?? ''
  itemMeasure = itemMeasure ?? ''
  itemPackName = itemPackName ?? ''

  if (itemSize === 1 && itemMeasure === 'each') {
    result = titleCase(DEFAULT_CONCATENATED_SELL_UNIT)
  } else {
    result = `${itemSizeString} ${itemMeasure} ${titleCase(itemPackName || '')}`
  }

  return result.replace(/\s+/g, ' ').trim()
}
