import { useTranslation } from 'react-i18next'

import { useSession } from '@/modules/access/hooks/useSession'
import useSessionUser from '@/modules/access/hooks/useSessionUser'

export default function UserMenu() {
  const { t } = useTranslation()
  const { currentOrganisation } = useSessionUser()
  const { currentUser } = useSession()

  const renderRole = () => {
    if (currentOrganisation?.isAPurchaser) {
      return t('general.role.purchaser', 'Purchaser')
    }
    if (!currentOrganisation?.isAPurchaser) {
      return t('general.role.supplier', 'Supplier')
    }
    return null
  }

  return (
    <>
      <p className="block px-4 pt-2 text-xxs">
        {t('access.signedInAs', 'Signed in as {{ legalEntity }}', {
          legalEntity: renderRole(),
        })}
      </p>
      <p className="block px-4 text-sm">{currentUser?.fullName}</p>
      <p className="block px-4 text-sm">{currentOrganisation?.name}</p>
      <p className="block break-words px-4 pb-2 text-sm font-semibold">{currentUser?.email}</p>
    </>
  )
}
