export enum ProductListType {
  Shop = 'Shop',
  BuyListBuilder = 'BuyListBuilder',
  AddInvoiceLine = 'AddInvoiceLine',
  AddCatalogProduct = 'AddCatalogProduct',
  AddReceivingNoteProduct = 'AddReceivingNoteProduct',
  AddBuyListProduct = 'AddBuyListProduct',
  AddRequisitionProduct = 'AddRequisitionProduct',
  AllProducts = 'AllProducts',
  SubstituteProduct = 'SubstituteProduct',
  AddRecipeIngredient = 'AddRecipeIngredient',
  AddTransferItem = 'AddTransferItem',
  AddInvoiceCreatorLine = 'AddInvoiceCreatorLine',
}
