import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useSession } from '@/modules/access/hooks/useSession'
import Button from '@/modules/shared/components/button/Button'
import {
  FeedbackModalFormInputs,
  FeedbackModalFormSchema,
} from '@/modules/shared/components/feedback-modal/schemas/FeedbackModalFormSchema'
import { sendFeedback } from '@/modules/shared/components/feedback-modal/sendFeedback'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { classNames } from '@/modules/shared/utils/classNames'

export interface FeedbackModalProps {
  showModal: boolean
  onCloseModal: () => void
}

export const FeedbackModal = ({ showModal, onCloseModal }: FeedbackModalProps) => {
  const { t } = useTranslation()
  const { currentUser } = useSession()
  const [loading, setLoading] = useState(false)

  const formMethods = useForm({
    resolver: zodResolver(FeedbackModalFormSchema),
    defaultValues: {
      message: '',
    },
  })

  const { handleSubmit, register, formState, reset } = formMethods

  const onSubmit: SubmitHandler<FeedbackModalFormInputs> = async (data) => {
    const { message } = data
    setLoading(true)
    const responseMessage = await sendFeedback({
      email: currentUser?.email || undefined,
      message: message,
      fullName: currentUser?.fullName || undefined,
    })
    displayToast({ title: responseMessage })
    setLoading(false)
    handleClose()
  }

  const handleClose = () => {
    reset()
    onCloseModal()
  }

  return (
    <Modal showModal={showModal} onCloseModal={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Panel className="flex min-h-[40vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]">
          <Modal.Title title={t('general.leaveFeedback', 'Leave Feedback')} onCloseModal={handleClose} />
          <Modal.Body>
            <div className="w-full text-sm">
              <p className="font-bold">{t('leaveFeedbackModal.title', 'Send Us Your Feedback')}</p>
              <p className="mb-2 text-gray-500">
                {t(
                  'leaveFeedbackModal.subtitle',
                  "We're working to make PurchasePlus the best it can be for our users! Please provide us with any feedback or general comments that you'd like us to hear."
                )}
              </p>
              <textarea
                rows={4}
                className={classNames(
                  'w-full rounded-md border border-gray-200 p-3 text-sm focus:outline-none focus:ring-primary',
                  {
                    'cursor-not-allowed border-gray-300 bg-gray-200': loading,
                  }
                )}
                aria-label={t('general.leaveFeedback', 'Leave Feedback')}
                placeholder={t('general.leaveFeedback', 'Leave Feedback')}
                {...register('message')}
                data-testid={`leave-feedback-input`}
                disabled={loading}
              />
              {formState.errors.message && (
                <span className="mt-2 block text-sm text-error">{formState.errors.message.message}</span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="flex flex-row items-center justify-end gap-2">
            <Button className="h-11 w-full rounded-md bg-gray-200 px-5 py-3 text-sm md:w-fit" onClick={handleClose}>
              {t('general.cancel', 'Cancel')}
            </Button>
            <Button
              className="flex h-11 items-center justify-center rounded-md bg-primary px-5 text-sm text-white"
              type="submit"
              disabled={loading}
            >
              {t('general.sendFeedback', 'Send Feedback')}
            </Button>
          </Modal.Footer>
        </Modal.Panel>
      </form>
    </Modal>
  )
}
