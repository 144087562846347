import { ReactNode } from 'react'

import { ColumnDefinition } from '@/modules/shared/components/table/types'
import { SubLine } from '@/modules/shared/types/SubLineType'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableSubLineProps<T> {
  filteredColumns: ColumnDefinition<T>[]
  subLine: SubLine
  renderExtraLineSubLine?: (record: SubLine, index: number) => ReactNode
  hasExtraLineSubLine?: (record: SubLine) => boolean
  columns: ColumnDefinition<T>[]
  index: number
  totalSubLines: number
}
export function TableSubLine<T>({
  filteredColumns,
  subLine,
  renderExtraLineSubLine,
  hasExtraLineSubLine,
  columns,
  index,
  totalSubLines,
}: TableSubLineProps<T>) {
  return (
    <>
      <tr>
        {filteredColumns.map((column, columnIdx) => {
          let nextIdx = columnIdx + 1
          let colSpan = 1

          // Check next columns for missing renderSubLine
          while (nextIdx < filteredColumns.length && !filteredColumns[nextIdx].renderSubLine) {
            colSpan++
            nextIdx++
          }

          return (
            column.renderSubLine &&
            (!column?.hidden || column?.alwaysVisible) && (
              <td
                key={column?.key + 'subLine'}
                colSpan={colSpan}
                style={{ maxWidth: `${column?.maxWidth}rem`, minWidth: `${column?.minWidth}rem` }}
                className={classNames(column?.cellStyles, 'break-words border-b', {
                  'w-full': column?.grow,
                  'border-none': renderExtraLineSubLine && hasExtraLineSubLine && !!hasExtraLineSubLine(subLine),
                })}
              >
                {column.renderSubLine(subLine)}
              </td>
            )
          )
        })}
      </tr>
      {renderExtraLineSubLine && hasExtraLineSubLine && hasExtraLineSubLine(subLine) && (
        <tr className={classNames({ 'border-b': index !== totalSubLines - 1 })}>
          <td colSpan={columns.length} className="space-y-2 px-4 pb-4">
            {renderExtraLineSubLine(subLine, index)}
          </td>
        </tr>
      )}
    </>
  )
}
