export interface CountBadgeProps {
  displayText?: string | number | null
}

const CountBadge = ({ displayText }: CountBadgeProps) => {
  if (!displayText) return null

  return <div className="w-min rounded bg-error px-2 py-1 text-xs font-semibold text-white">{displayText}</div>
}

export default CountBadge
