import { useTranslation } from 'react-i18next'

import { DeliveryFees } from '@/modules/requisitions/components/delivery-fees/DeliveryFees'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { Supplier } from '@/modules/shared/components/product-modal/types/Supplier'

interface DeliveryFeesProps {
  supplier: Supplier
}

export default function DeliveryFeesDetails({ supplier }: DeliveryFeesProps) {
  const { t } = useTranslation()
  return (
    <Accordion
      title={t('general.deliveryFees', 'Delivery Fees')}
      buttonClassName="px-3 h-11 mt-2.5"
      titleClassName="font-bold"
      data-testId="accordion-delivery-fees-btn"
    >
      <p className="my-3 text-sm">
        {t(
          'shopPage.productModal.accordions.deliveryFees.descriptionText',
          'The table below shows delivery fees for this Supplier by total order value, excluding tax. The delivery fees are based on your trading agreement with this Supplier, and the selected delivery address for this Purchase Requisition. You can change the delivery address below if needed. Delivery fees are a general guide and the supplier reserves the right to charge additional delivery fees based on weight or volume or certain products.'
        )}
      </p>
      <DeliveryFees deliveryChargeConfiguration={supplier?.deliveryChargeConfiguration || undefined} />
    </Accordion>
  )
}
