import { Supplier } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'

export function formatSupplierBillingAddress(
  billingAddresses: Pick<
    Supplier,
    | 'billingAddressLine1'
    | 'billingAddressLine2'
    | 'billingAddressCity'
    | 'billingAddressStateProvince'
    | 'billingAddressPostalCode'
  >
): string {
  const {
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressStateProvince,
    billingAddressPostalCode,
  } = billingAddresses || {}

  const arr: string[] = [
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressStateProvince,
    billingAddressPostalCode,
  ]
    .filter((e) => Boolean(e))
    .map((e) => String(e))

  return arr.reduce((acc, curr) => `${acc} ${curr}`, '').trim()
}
