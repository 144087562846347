import { useTranslation } from 'react-i18next'

import { formatSupplierBillingAddress } from '@/modules/requisitions/utils/formatSupplierBillingAddress'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { Supplier } from '@/modules/shared/components/product-modal/types/Supplier'
import SupplierTags from '@/modules/shared/components/supplier-tags/SupplierTags'
import { TileDetailsTableItem } from '@/modules/shared/components/tile/tile-items/TileDetailsTableItem'

interface SupplierDetailsProps {
  supplier: Supplier
}

export default function SupplierDetails({ supplier }: SupplierDetailsProps) {
  const { name, telephone, email } = supplier || {}
  const { t } = useTranslation()
  return (
    <Accordion
      title={t('general.supplierDetails', 'Supplier Details')}
      buttonClassName="px-3 h-11 mt-2.5"
      titleClassName="font-bold"
    >
      <section data-testid="supplier-details" className="mb-4 mt-2 border">
        <TileDetailsTableItem
          title={t('general.supplier', 'Supplier')}
          value={name}
          tooltip={t('supplierDetails.supplierDesc', 'The name of the Supplier.')}
        />
        <TileDetailsTableItem
          title={t('general.headquarters', 'Headquarters')}
          value={
            supplier &&
            formatSupplierBillingAddress({
              billingAddressLine1: supplier?.billingAddressLine1 || null,
              billingAddressLine2: supplier?.billingAddressLine2 || null,
              billingAddressCity: supplier?.billingAddressCity || null,
              billingAddressStateProvince: supplier?.billingAddressStateProvince || null,
              billingAddressPostalCode: supplier?.billingAddressPostalCode || null,
            })
          }
          tooltip={t('supplierDetails.headquartersDesc', 'The main address of the Supplier.')}
        />
        <TileDetailsTableItem
          title={t('general.contactPhone', 'Contact Phone')}
          value={telephone}
          tooltip={t('supplierDetails.phoneDesc', 'The contact phone number provided by the Supplier.')}
        />
        <TileDetailsTableItem
          title={t('general.contactEmail', 'Contact Email')}
          value={email}
          tooltip={t('supplierDetails.emailDesc', 'The contact email address provided by the Supplier.')}
        />
        <TileDetailsTableItem
          title={t('general.supplierTags', 'Supplier Tags')}
          value={<SupplierTags preferredSupplier={!!supplier.supplierRelationship?.preferredSupplier} />}
          tooltip={t('supplierDetails.tagsDesc', 'The tags that your Organisation has tagged this Supplier with.')}
        />
      </section>
    </Accordion>
  )
}
