import { useState } from 'react'

import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ImageItem } from '@/modules/shared/components/image/ImageItem'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { classNames } from '@/modules/shared/utils/classNames'

export interface ProductImageProps {
  image: string
  clickToOpenModal?: boolean
  line?: RequisitionLine
  supplier?: Supplier
}

export const ProductImage = ({ image, clickToOpenModal, line, supplier }: ProductImageProps) => {
  const [showProductDetailsModal, setShowProductDetailsModal] = useState<boolean>(false)
  return (
    <>
      <ImageItem
        src={image}
        alt={'product'}
        onClick={() => clickToOpenModal && setShowProductDetailsModal(true)}
        className={classNames('h-[3.75rem] w-[3.75rem] border border-gray-200', { 'cursor-pointer': clickToOpenModal })}
      />
      {line && (
        <ProductModal
          showModal={showProductDetailsModal}
          setShowModal={setShowProductDetailsModal}
          line={line}
          supplier={supplier as Supplier}
        />
      )}
    </>
  )
}
